import { useCallback, useState } from 'react'

export const usePagination = (
  initOffset = 0,
  initLimit = 10,
  initPageNumber = 1,
): {
  limit: number
  offset: number
  pageNumber: number
  onShowSizeChange: (pageNumber: number, pageSize: number) => void
  onOffsetChange: (pageNumber: number) => void
  onPageChange: (pageNumber: number) => void
} => {
  const [offset, setOffset] = useState(initOffset)
  const [limit, setLimit] = useState(initLimit)
  const [pageNumber, setPageNumber] = useState(initPageNumber)

  const onShowSizeChange = (_: number, pageSize: number) => {
    setLimit(pageSize)
  }

  const onOffsetChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit)
  }

  const onPageChange = useCallback((pageNumber: number) => {
    setPageNumber(pageNumber)
  }, [])

  return {
    limit,
    offset,
    pageNumber,
    onShowSizeChange,
    onOffsetChange,
    onPageChange,
  }
}
