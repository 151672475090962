import { useEffect, useState } from 'react'

export const useTimeout = (
  initialTime?: number,
): [number, React.Dispatch<React.SetStateAction<number>>] => {
  const [waitTime, setWaitTime] = useState(initialTime || 0)

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (waitTime) {
        setWaitTime(time => time - 1000)
      }
    }, 1000)

    return () => clearTimeout(timeout)
  }, [waitTime])

  return [waitTime, setWaitTime]
}
