export type ExtendName<T> = T & { name: string }
export type ExtendNumberValue<T> = T & { value: number }
export enum SortOrder {
  Ascend = 'asc',
  Descend = 'desc',
}

export const sortByName = (
  data: ExtendName<any>[],
  sortOrder: SortOrder = SortOrder.Ascend,
): ExtendName<any>[] => {
  if (sortOrder === SortOrder.Ascend) {
    return data.sort((a, b) => a.name.localeCompare(b.name))
  }

  return data.sort((a, b) => b.name.localeCompare(a.name))
}

export const sortByValue = (
  data: ExtendNumberValue<unknown>[],
  sortOrder: SortOrder = SortOrder.Ascend,
): ExtendNumberValue<any>[] => {
  if (sortOrder === SortOrder.Ascend) {
    return data.sort((a, b) => a.value - b.value)
  }

  return data.sort((a, b) => b.value - a.value)
}

// Find elements in the first but not in the second array
export const getDifference = <T = any>(array1: T[], array2: T[]): T[] => {
  const set2 = new Set(array2)
  return array1.filter(x => !set2.has(x))
}

// Find elements in the first array but also existed in the second array
export const getIntersection = <T = any>(array1: T[], array2: T[]): T[] => {
  const set2 = new Set(array2)
  return array1.filter(value => set2.has(value))
}

export const asyncSome = async <T>(
  arr: T[],
  predicate: (item: T) => Promise<boolean>,
): Promise<boolean> => {
  for (const item of arr) {
    if (await predicate(item)) return true
  }
  return false
}

export const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}
