export const convertHexToRGBA = (hexCode: string, opacity = 1): string => {
  let hex = hexCode?.replace('#', '')

  if (hex?.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
  }

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100
  }

  return `rgba(${r},${g},${b},${opacity})`
}

export const addAlpha = (color: string, opacity: number): string => {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)
  return color + _opacity.toString(16).toUpperCase()
}

export const isDarkColor = (hex?: string): boolean => {
  if (!hex) return false

  const rgb = hex.substring(1, 7)
  const r = parseInt(rgb.substring(0, 2), 16) // hexToR
  const g = parseInt(rgb.substring(2, 4), 16) // hexToG
  const b = parseInt(rgb.substring(4, 6), 16) // hexToB
  const grayScale = r * 0.299 + g * 0.587 + b * 0.114
  return grayScale < 186
}

export enum Theme {
  DARK,
  LIGHT,
}
