import axios, { AxiosPromise, AxiosRequestConfig, CancelTokenSource } from 'axios'
import { useRef } from 'react'

export interface APIParams<T = object> {
  options?: AxiosRequestConfig
  variables?: T
}

export type Response<T = any> = AxiosPromise<T>
export const REQUEST_CANCEL_MESSAGE = 'Operation canceled due to new request'

const CancelToken = axios.CancelToken

export const useCancelTokenRequest = (
  request: (params: APIParams<any>) => Response,
): ((params: APIParams<any>) => AxiosPromise) => useRef(withCancelToken(request)).current

const withCancelToken = (
  request: (params: APIParams<any>) => AxiosPromise,
): ((params: APIParams<any>) => AxiosPromise) => {
  // eslint-disable-next-line prefer-const
  let cancelSource: CancelTokenSource | undefined

  return (params: APIParams): AxiosPromise => {
    if (typeof cancelSource !== 'undefined') {
      cancelSource.cancel(REQUEST_CANCEL_MESSAGE)
    }

    cancelSource = CancelToken.source()

    return request({
      options: {
        ...params.options,
        cancelToken: cancelSource.token,
      },
      variables: params.variables,
    })
  }
}
