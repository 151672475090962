export const LOGO = `https://ngantin.vn/media/logo/J6gUiaEN1AhyJ4qj6do4fOu4.png`
export const LOGO_FULL = `https://ngantin.vn/media/logo/J6gUiaEN1AhyJ4qj6do4fOu4.png`
/* Select popover */
export const ALIGN_POPOVER = {
  points: ['tl', 'bl'], // align dropdown bottom-left to top-left of input element
  offset: [0, 0], // align offset
  overflow: {
    adjustX: 0,
    adjustY: 0, // do not auto flip in y-axis
  },
}

export const MODAL_WIDTH = {
  SMALL: 400,
  MEDIUM: 640,
  LARGE: 840,
  EXTREMELY_LARGE: 1024,
}

export const PAGE_SIZE_OPTIONS = [5, 10, 20, 50]

export const NOT_AVAILABLE = 'N/A'
export const NOT_PRINT_MESSAGE = 'NOT_PRINT_MESSAGE'

export const CURRENCY_EXCHANGE_RATE = 25455
