import { Avatar, Button, Col, Row, Typography } from 'antd'
import { useTimeout } from 'packages/utilities'
import React, { useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import { appRoutes } from '@/config/appRoutes'
import { LOGO } from '@/utilities/constanst'

export enum RedirectType {
  NoAuth = 'noAuth',
}
const COUNT_DOWN_TIME = 10000

const RedirectPage: React.FC = () => {
  const history = useHistory()
  const [waitTime] = useTimeout(COUNT_DOWN_TIME)
  const { search } = useLocation()
  const urlSearchParams = new URLSearchParams(search)
  const type = urlSearchParams?.get('type') as RedirectType
  const next = urlSearchParams?.get('next') as RedirectType

  const redirect = () =>
    history.push({ pathname: appRoutes.publicLogin, search: next ? `?next=${next}` : '' })

  useEffect(() => {
    if (type === RedirectType.NoAuth && waitTime === 0) {
      redirect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, type, waitTime, next])

  if (type === RedirectType.NoAuth) {
    const waitTimeInSecond = waitTime / 1000

    return (
      <Row style={{ height: '100vh', width: '100%' }} justify='center' align='middle'>
        <Col span={12} style={{ textAlign: 'center', margin: 'auto' }}>
          <Avatar size={75} src={LOGO} style={{ marginBottom: 32 }} />
          <Trans i18nKey='$errorMessage.Invalid token'>
            <Typography.Title level={1} style={{ marginBottom: 14 }}>
              Your session has expired!
            </Typography.Title>
            <Typography.Text type='secondary'>
              (Redirect in {{ waitTimeInSecond }})
            </Typography.Text>
            <Typography.Paragraph type='secondary' style={{ margin: '32px 0' }}>
              We limit the session length to 7 days to keep your account secure. The
              session will also expire on all devices when you log out. Please log in
              again after being redirected.
            </Typography.Paragraph>
            <Button type='primary' onClick={() => redirect()}>
              Redirect Now
            </Button>
          </Trans>
        </Col>
      </Row>
    )
  }

  return null
}

export default RedirectPage
