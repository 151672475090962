import React, { SVGProps } from 'react'

const Menu01: React.FC<SVGProps<SVGSVGElement>> = ({
  width = 24,
  height = 24,
  stroke = '#000',
  fill = 'none',
  ...props
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    fill={fill}
    viewBox='0 0 24 24'
    {...props}>
    <path
      stroke={stroke}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M3 12h18M3 6h18M3 18h18'
    />
  </svg>
)
export default Menu01
