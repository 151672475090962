import { Time, TimeRange, TimeUnit, TimeWindow } from '../types/datetime'
import dayjs, { Dayjs, DAYJS_FORMAT } from './dayjs'

// Get start and end time based on the window and datetime format
export const getWindowTimeRange = (
  window: TimeWindow,
  format?: string,
): [string, string] | [Dayjs, Dayjs] => {
  const unit = window.unit as TimeUnit

  const start = dayjs().subtract(window.length, unit).startOf(unit)
  const end = window.is_current
    ? dayjs().endOf('day')
    : dayjs().subtract(1, unit).endOf(unit)

  if (format !== undefined) {
    return [start.format(format), end.format(format)]
  }

  return [start, end]
}

export const getPreviousTimeRange = (time: Time): TimeRange | undefined => {
  if (!time) return

  const prevTime = {} as TimeRange
  prevTime.unit = time.range.unit

  // Get previous time based on time window
  if (time.window) {
    const [start, _] = getWindowTimeRange(time.window, DAYJS_FORMAT.DEFAULT) as [
      string,
      string,
    ]
    const windowUnit = time.window.unit as TimeUnit

    if (time.window.is_current) {
      prevTime.start = dayjs()
        .subtract(1 + time.window.length * 2, windowUnit)
        .startOf(windowUnit)
        .format()
      prevTime.end = dayjs()
        .subtract(1 + time.window.length, windowUnit)
        .endOf('day')
        .format()
    } else {
      prevTime.start = dayjs(start)
        .startOf('day')
        .subtract(time.window.length, windowUnit)
        .format()
      prevTime.end = dayjs(start).endOf('day').subtract(1, 'day').format()
    }
    return prevTime
  }

  // Get previous time based on time range (if there's no time window)
  const duration = dayjs(time.range.end).diff(dayjs(time.range.start), 'day')
  prevTime.start = dayjs(time.range.start)
    .startOf('day')
    .subtract(duration + 1, 'day')
    .format()
  prevTime.end = dayjs(time.range.start).endOf('day').subtract(1, 'day').format()

  return prevTime
}

// Get the date time format base on time unit breakdown in tooltip and axis tick
export const getDatetimeFormat = (
  timeUnit: TimeUnit | undefined | null,
  preferedDefaultFormat = DAYJS_FORMAT.SHORT_DAY_AND_MONTH,
): string => {
  if (!timeUnit) return preferedDefaultFormat

  switch (timeUnit) {
    case TimeUnit.Hour:
      return DAYJS_FORMAT.HOUR
    case TimeUnit.Day:
      return preferedDefaultFormat
    case TimeUnit.Week:
      return preferedDefaultFormat
    case TimeUnit.Month:
      return DAYJS_FORMAT.SHORT_MONTH_AND_YEAR
    case TimeUnit.Year:
      return DAYJS_FORMAT.YEAR
    default:
      return preferedDefaultFormat
  }
}

export const violationTimeRanges = [
  dayjs().utc(true).subtract(24, 'hour').format(),
  dayjs().utc(true).subtract(7, 'day').format(),
  dayjs().utc(true).subtract(30, 'day').format(),
]

export const convertFromUTC = (dateString?: string, format?: string): string => {
  if (!dateString) return dayjs().format(format)
  return dayjs.utc(dateString).local().format(format)
}

// Get time unit based on the different between start and end time
export const getTimeUnit = (start: string, end: string): TimeUnit => {
  const difference = Math.abs(dayjs(start).diff(dayjs(end), TimeUnit.Day))

  switch (true) {
    case difference <= 90:
      return TimeUnit.Day

    case difference <= 365:
      return TimeUnit.Week

    default:
      return TimeUnit.Month
  }
}

// dayjs.isValid() method is only validate if the datestring is parsed correctly
// It cannot tell if the string is valid datetime value
// For example dayjs(1).isValid() will return true
// This function will check specifically if the datestring is in ISO8601 format
export const isDefaultDatetimeFormat = (dateString: string): boolean => {
  const defaultDatestringFormats = ['YYYY-MM-DDTHH:mm:ssZ', 'YYYY-MM-DDTHH:mm:ss']
  return defaultDatestringFormats.some(format =>
    dayjs(dateString, format, true).isValid(),
  )
}

export enum TimePresetKey {
  Today = 'today',
  Last7Days = 'last7days',
  Last30Days = 'last30days',
  Last90Days = 'last90days',
  Last365Days = 'last365days',
  MonthToDate = 'monthToDate',
  Custom = 'custom',
}

export const TIME_PRESETS = [
  {
    name: 'Last 7 days',
    key: TimePresetKey.Last7Days,
    unit: TimeUnit.Day,
    length: 6,
    is_current: true,
  },
  {
    name: 'Last 30 days',
    key: TimePresetKey.Last30Days,
    unit: TimeUnit.Day,
    length: 29,
    is_current: true,
  },
  {
    name: 'Last 90 days',
    key: TimePresetKey.Last90Days,
    unit: TimeUnit.Day,
    length: 89,
    is_current: true,
  },
  {
    name: 'Last 365 days',
    key: TimePresetKey.Last365Days,
    unit: TimeUnit.Day,
    length: 364,
    is_current: true,
  },
  {
    name: 'Today',
    key: TimePresetKey.Today,
    unit: TimeUnit.Day,
    length: 0,
    is_current: true,
  },
  {
    name: 'Month To Date',
    key: TimePresetKey.MonthToDate,
    unit: TimeUnit.Month,
    length: 0,
    is_current: true,
  },
]

export const getTimeFromWindow = (window: TimeWindow): Time => {
  const [start, end] = getWindowTimeRange(window, DAYJS_FORMAT.DEFAULT) as [
    string,
    string,
  ]
  return {
    range: { start, end, unit: getTimeUnit(start, end) },
    window,
  }
}

export const Last7Days = getTimeFromWindow({
  length: 6,
  unit: TimeUnit.Day,
  is_current: true,
})

export const Last30Days = getTimeFromWindow({
  length: 29,
  unit: TimeUnit.Day,
  is_current: true,
})

export const Last90Days = getTimeFromWindow({
  length: 89,
  unit: TimeUnit.Day,
  is_current: true,
})

export const MonthToDate = getTimeFromWindow({
  length: 0,
  unit: TimeUnit.Month,
  is_current: true,
})

export const formatDateRange = (
  start?: string,
  end?: string,
  format?: string,
): string => {
  const formatTime = value =>
    dayjs(value).format(format || DAYJS_FORMAT.SHORT_DAY_MONTH_YEAR)

  const isEndToday = dayjs(end).isSame(dayjs(), 'day')

  if (isEndToday) {
    const isStartToday = dayjs(start).isSame(dayjs(), 'day')
    if (isStartToday) return 'Today'

    const isStartOfThisMonth = dayjs(start).isSame(dayjs().startOf('month'), 'day')
    if (isStartOfThisMonth) return 'Month To Date'

    return `Last ${dayjs().diff(dayjs(start), 'day') + 1} days`
  }

  const startDate = formatTime(start)
  if (!end) return startDate

  const endDate = formatTime(end)

  if (startDate === endDate) return endDate
  return `${startDate} - ${endDate}`
}

export const getLocalTime = (timestamp?: string): dayjs.Dayjs | null => {
  return timestamp ? dayjs.utc(timestamp).local() : null
}
