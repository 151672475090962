import { Button, Form, Input } from 'antd'
import { ColumnType } from 'antd/es/table'
import { AxiosError } from 'axios'
import { Copyable, CustomizedModal, CustomizedTable, FormItem } from 'packages/ui'
import { useDebounce, usePagination } from 'packages/utilities'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'

import AppAPIService from '@/services/AppAPI'
import { queryClient } from '@/services/reactQuery'
import { QueryKeys } from '@/services/types'
import { FastPagination } from '@/types'
import { Workspace } from '@/types/server/workspace'

enum FormModalField {
  Name = 'name',
}

const WorkspacesPage: React.FC = () => {
  const { t } = useTranslation()

  const { pageNumber, onPageChange, onShowSizeChange, limit } = usePagination(0, 5, 1)
  const [workspaceFormVisible, setWorkspaceFormVisible] = React.useState(false)
  const [searchKey, setSearchKey] = React.useState('')
  const debouncedSearchKey = useDebounce(searchKey, 500)

  const [form] = Form.useForm()

  useEffect(() => {
    onPageChange(1)
  }, [debouncedSearchKey])

  const changeSearchKey = (e: { target: HTMLInputElement }) => {
    setSearchKey(e.target.value)
  }

  const queryKeys = [
    QueryKeys.AdminGetWorkspaces,
    {
      page: pageNumber,
      per_page: limit,
      search: debouncedSearchKey,
    },
  ]

  const workspacesQuery = useQuery<FastPagination<Workspace>, AxiosError>(
    queryKeys,
    async ({ queryKey: [, params] }) => {
      const res = await AppAPIService.adminGetWorkspaces({
        options: {
          params,
        },
      })
      return res.data
    },
    { enabled: true },
  )

  const { mutate: createWorkspace } = useMutation<void, AxiosError, { name: string }>(
    async (data: { name: string }) => {
      await AppAPIService.adminCreateWorkspace({
        options: {
          data,
        },
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys)
        setWorkspaceFormVisible(false)
        onPageChange(1)
      },
    },
  )

  const handleCreateWorkspace = async () => {
    try {
      const values = await form.validateFields()
      createWorkspace({ name: values[FormModalField.Name] })
    } catch (error) {
      console.error(error)
    }
  }

  document.title = t('Tài khoản')

  const columns: ColumnType<any>[] = [
    { title: 'ID', key: 'id', dataIndex: 'id' },
    {
      title: t('Tên công ty'),
      key: 'name',
      dataIndex: 'name',
      width: 300,
    },
    {
      title: t('Link đăng ký khách hàng'),
      key: 'customer_register_link',
      dataIndex: 'slug',
      width: 300,
      render: (slug: string) => (
        <Copyable title='Link' value={`${window.location.origin}/${slug}/register`} />
      ),
    },
  ]

  return (
    <>
      <CustomizedTable
        loading={workspacesQuery.isLoading}
        columns={columns}
        title={t('Tất cả công ty')}
        totalUnit={t('công ty')}
        tableLayout='fixed'
        onSearchChange={changeSearchKey}
        searchPlaceHolder={t('Tìm kiếm công ty')}
        dataSource={workspacesQuery.data?.items}
        rowKey={record => record.id}
        extra={
          <Button
            type='primary'
            className='py-2 mr-2'
            onClick={() => setWorkspaceFormVisible(true)}>
            {t('Tạo tài khoản công ty mới')}
          </Button>
        }
        pagination={{
          current: pageNumber + 1,
          showSizeChanger: true,
          onShowSizeChange: onShowSizeChange,
          pageSize: limit,
          total: workspacesQuery.data?.total,
          onChange: pageNumber => onPageChange(pageNumber - 1),
        }}
      />

      <CustomizedModal
        title={t('Tạo công ty mới')}
        open={workspaceFormVisible}
        onCancel={() => setWorkspaceFormVisible(false)}
        hideOk>
        <Form
          form={form}
          onFinish={handleCreateWorkspace}
          className='flex flex-col gap-4 w-full'>
          <FormItem name={FormModalField.Name} label={t('Tên công ty')}>
            <Input />
          </FormItem>
          <Button type='primary' htmlType='submit'>
            {t('Tạo công ty')}
          </Button>
        </Form>
      </CustomizedModal>
    </>
  )
}

export default WorkspacesPage
