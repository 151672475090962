import React, { SVGProps } from 'react'

const Route: React.FC<SVGProps<SVGSVGElement>> = ({
  width = 20,
  height = 20,
  stroke = '#667085',
  fill = 'none',
  ...props
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    fill={fill}
    viewBox='0 0 20 20'
    {...props}>
    <g clipPath='url(#a)'>
      <path
        stroke={stroke}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.67}
        d='M9.583 4.167h.362c2.54 0 3.81 0 4.291.456.417.394.602.975.49 1.537-.131.65-1.168 1.384-3.24 2.85l-3.388 2.396c-2.073 1.467-3.11 2.2-3.24 2.85a1.667 1.667 0 0 0 .489 1.538c.482.456 1.752.456 4.291.456h.779M6.667 4.167a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm11.666 11.666a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h20v20H0z' />
      </clipPath>
    </defs>
  </svg>
)

export default Route
