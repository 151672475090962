import { Loading } from 'packages/ui'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './config/i18n'
import Container from './routes'
import './styles/main.scss'
import './styles/index.css'

import { message } from 'antd'

message.config({
  top: 50,
})

function start() {
  ReactDOM.render(
    <React.StrictMode>
      <Suspense fallback={<Loading spinProps={{ delay: 500 }} />}>
        <Container />
      </Suspense>
    </React.StrictMode>,
    document.getElementById('root'),
  )
}

start()
