import React, { SVGProps } from 'react'

const ChevronDown: React.FC<SVGProps<SVGSVGElement>> = ({
  width = 16,
  height = 16,
  stroke = '#667085',
  fill = 'none',
  ...props
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    fill={fill}
    viewBox='0 0 16 16'
    {...props}>
    <path
      stroke={stroke}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.333}
      d='m4 6 4 4 4-4'
    />
  </svg>
)

export default ChevronDown
