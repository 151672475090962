import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { appRoutes } from '@/config/appRoutes'
import WorkspacesPage from '@/features/workspaces/workspaces.page'

const Workspaces: React.FC = () => {
  return (
    <Switch>
      <Route exact path={appRoutes.workspaces} component={WorkspacesPage} />
    </Switch>
  )
}

export default Workspaces
