import { Row, Spin, SpinProps } from 'antd'
import React from 'react'

interface Props {
  containerProps?: any
  spinProps?: SpinProps
}

export const Loading: React.FC<Props> = ({ containerProps, spinProps }) => {
  return (
    <Row
      className='loading'
      style={{ height: 480, width: '100%' }}
      align='middle'
      justify='center'
      {...containerProps}>
      <Spin spinning={true} {...spinProps} />
    </Row>
  )
}
