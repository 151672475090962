import { message } from 'antd'
import axios, { AxiosError } from 'axios'
import { isEmptyObject } from 'packages/utilities'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { RedirectType } from '@/components/RedirectPage'
import { appRoutes } from '@/config/appRoutes'
import { AuthContext } from '@/contexts/AuthProvider'
import { capitalizeFirstLetter } from '@/utilities/input'

const MESSAGE_DURATION = 5

enum ErrorMessage {
  InvalidToken = 'Invalid token',
  InactiveToken = 'Inactive token',
  SomethingWentWrong = 'Something went wrong',
}

export enum ErrorStatus {
  InvalidCredentials = 401,
  Forbidden = 403,
}

type ValidationError = {
  loc: string[]
  msg: string
  type: string
}

type BaseError = {
  message: string
  params?: Record<string, string>
}

export type ErrorResponse = {
  detail: ValidationError[] | BaseError
}

const FORCE_LOGOUT_ERRORS = [ErrorMessage.InvalidToken, ErrorMessage.InactiveToken]

const useHandleAPIError = (): ((error: AxiosError) => string | undefined) => {
  const history = useHistory()
  const { t } = useTranslation()
  const authData = useContext(AuthContext)

  const handleAPIError = (error: AxiosError) => {
    const isForbidden = error.response?.status === ErrorStatus.Forbidden
    const isGetRequest =
      error.config.method?.toLowerCase() === 'get' ||
      error.config.url?.indexOf('list') !== -1 ||
      error.config.url?.endsWith('metric-analysis')
    const alert = !isForbidden || !isGetRequest
    // If request is canceled by Axios, do nothing
    if (axios.isCancel(error)) return

    if (FORCE_LOGOUT_ERRORS.includes(error.response?.data.detail?.message)) {
      authData?.logout()

      history.push({
        pathname: appRoutes.redirect,
        search: `?type=${RedirectType.NoAuth}&next=${window.location.pathname}`,
      })
      return
    }

    if (
      !error?.response ||
      error.response.status === 500 ||
      isEmptyObject(error.response.data?.detail)
    ) {
      const msg = t(`$errorMessage.${ErrorMessage.SomethingWentWrong}`)

      if (alert) {
        message.error({
          content: msg,
          key: msg,
          duration: MESSAGE_DURATION,
        })
      }

      return msg
    }

    const detail = error.response.data.detail as ErrorResponse['detail']

    if (Array.isArray(detail)) {
      detail.forEach(item => {
        const msg = t(item.msg)
        if (alert) {
          message.error({
            content: msg,
            key: msg,
            duration: MESSAGE_DURATION,
          })
        }
      })
      return detail.map(item => t(item.msg)).join(', ')
    }

    if (detail.message) {
      const translatedParams = Object.entries(detail.params || {}).reduce(
        (acc, [key, value]) => {
          acc[key] = t(value)
          return acc
        },
        {} as Record<string, string>,
      )
      const msg = t(detail.message, translatedParams)
      if (alert) {
        message.error({
          content: capitalizeFirstLetter(msg),
          key: msg,
          duration: MESSAGE_DURATION,
        })
      }
      return msg
    }
  }

  return handleAPIError
}

export default useHandleAPIError
