import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'
import rateLimit from 'axios-rate-limit'
import queryString from 'query-string'

import { AuthData, AuthType } from '@/contexts/AuthProvider'
import { authLocalItem } from '@/contexts/localItems'
import { globalWorkspace } from '@/contexts/useWorkspace'
import { Workspace } from '@/types/server/workspace'

export let controller = new AbortController()

export enum HeaderKey {
  AuthType = 'Auth-Type',
  IsShowcase = 'Is-Showcase',
  Authorization = 'Authorization',
  OrganizationId = 'Organization-Id',
  Accept = 'Accept',
  AcceptLanguage = 'Accept-Language',
  ContentType = 'Content-Type',
  AppAuthorization = 'App-Authorization',
}

export interface APIParams<T = object> {
  options?: AxiosRequestConfig
  variables?: T
}

export type Response<T = any> = AxiosPromise<T>

export const getHeaders = (): AxiosRequestConfig['headers'] => {
  const workspace = globalWorkspace as Workspace | undefined
  const auth = authLocalItem.get() as AuthData | undefined
  const language = localStorage.getItem('i18nextLng')

  const header = {
    [HeaderKey.ContentType]: 'application/json',
    [HeaderKey.Accept]: 'application/json',
    [HeaderKey.Authorization]: `Bearer ${auth?.accessToken}`,
    [HeaderKey.AuthType]: auth?.authType || AuthType.Account,
    [HeaderKey.IsShowcase]: workspace ? (!!workspace.is_showcase).toString() : 'false',
    [HeaderKey.AcceptLanguage]: language,
  }

  if (workspace?.id) {
    header[HeaderKey.OrganizationId] = workspace.id
  }

  return header
}

// Remove [] from url since Fast API does not support this syntax
// Before: id[0]=foo&id[1]=bar&id[2]=baz
// After: id=foo&id=bar&id=baz
const getDefaultOptions = () => ({
  signal: controller.signal,
  paramsSerializer: function (params) {
    return queryString.stringify(params)
  },
})

export const client = axios.create()

export const cancelPendingRequest = (): void => {
  controller.abort()
  controller = new AbortController()
}

// Requests are sent synchronously (usecase: fetch event batch in Source Debugger)
const syncRequestInstance = axios.create()

const syncClient = rateLimit(syncRequestInstance, {
  maxRequests: 1,
})

// Default request to be used only by core-ui platform
export const request = (options: AxiosRequestConfig): AxiosPromise => {
  return client({ ...getDefaultOptions(), ...options })
}

// Synchronous request
export const syncRequest = (options: AxiosRequestConfig): AxiosPromise => {
  return syncClient({ ...getDefaultOptions(), ...options })
}
