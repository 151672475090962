import { getDifference } from 'packages/utilities'
import React, { useState } from 'react'
import { matchPath, useHistory } from 'react-router-dom'

import { appRoutes, independentRoutes, publicRoutes } from '@/config/appRoutes'
import { queryClient } from '@/services/reactQuery'

import { authLocalItem, deleteAllLocalItems } from './localItems'
import { useWorkspace } from './useWorkspace'

export enum AuthType {
  Account = 'account',
  Token = 'token',
}

export type AuthData = {
  accessToken: string
  authType: string
}

export type SetAuthDataOptions = {
  redirect?: boolean | string
}

type TAuthContext =
  | {
      authData: AuthData | undefined
      login: (newAuthData: AuthData, options?: SetAuthDataOptions) => void
      logout: () => void
    }
  | undefined

export const AuthContext = React.createContext<TAuthContext>(undefined)

export const AuthProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const history = useHistory()
  const workspace = useWorkspace()

  const [authData, setAuthData] = useState<AuthData | undefined>(
    () => (authLocalItem.get() as AuthData) || undefined,
  )

  const [redirect, setRedirect] = useState<boolean | string | undefined>()

  const renderContent = () => {
    const pathName = window?.location?.pathname
    const params = new URLSearchParams(window?.location?.search)
    const redirectParam = params.get('next')

    const getRedirectURL = () => {
      if (typeof redirect === 'string') return `?next=${redirect}`
      if (redirect === true) return `?next=${pathName}`
      return ''
    }

    // Handle redirecting
    const isPrivateRoutes = !Object.values(publicRoutes).find(route =>
      matchPath(pathName, { path: route }),
    )

    const notAccessibleAfterLoginRoutes = getDifference(
      // User should be able to setup password from anywhere
      Object.values(publicRoutes).filter(
        value =>
          ![publicRoutes.passwordSetup, publicRoutes.supplierPackaging].includes(value),
      ),
      Object.values(independentRoutes),
    )

    if (!authData && isPrivateRoutes) {
      history.push({
        pathname: appRoutes.publicLogin,
        search: getRedirectURL(),
      })
    }

    const isNotAccessibleAfterLogin = !!notAccessibleAfterLoginRoutes.find(route =>
      matchPath(pathName, route),
    )

    if (authData && isNotAccessibleAfterLogin) {
      history.push({ pathname: redirectParam || appRoutes.root })
    }

    return children
  }

  const login = (nextAuthData: AuthData, options?: SetAuthDataOptions) => {
    authLocalItem.set(nextAuthData)
    setAuthData(nextAuthData)
    setRedirect(options?.redirect)
  }

  const logout = () => {
    workspace.set(undefined)
    queryClient.clear()
    setAuthData(undefined)
    setRedirect(undefined)
    deleteAllLocalItems()
  }

  return (
    <AuthContext.Provider value={{ authData, login, logout }}>
      {renderContent()}
    </AuthContext.Provider>
  )
}
