import { MatrixFieldNameRegex } from '../main'

export const getSurveyAnswersPayload = (
  values: Record<string, any>,
  questionIds: Set<number>,
): Record<string, any> => {
  return Object.keys(values).reduce((acc: any, key) => {
    if (key.match(MatrixFieldNameRegex)) {
      const [questionId, rowId] = key.split('-')
      acc[questionId] = {
        ...acc[questionId],
        [rowId]: values[key],
      }
    }

    if (questionIds.has(Number(key))) {
      acc[key] = values[key]
    }
    return acc
  }, {})
}

enum FontStyle {
  SEMI_BOLD = 'semi-bold',
  NORMAL = 'normal',
}

export const loadCustomFonts = (fontUrls: Record<FontStyle, string>): void => {
  const normalFont = new FontFace(FontStyle.NORMAL, `url(${fontUrls[FontStyle.NORMAL]})`)
  const semiboldFont = new FontFace(
    FontStyle.SEMI_BOLD,
    `url(${fontUrls[FontStyle.SEMI_BOLD]})`,
  )
  ;(document as any).fonts.add(normalFont)
  ;(document as any).fonts.add(semiboldFont)
  normalFont.load()
  semiboldFont.load()
}

export const getQuestionTranslationKey = (
  questionId: number | string,
  optionName?: string,
  optionId?: number | string,
  optionExtra?: string,
): string => {
  let key = `question.${questionId}`
  if (optionName) key += `.${optionName}`
  if (optionId) key += `.${optionId}`
  if (optionExtra) key += `.${optionExtra}`
  return key
}
