import React, { SVGProps } from 'react'

const BarChartSquareUp: React.FC<SVGProps<SVGSVGElement>> = ({
  width = 20,
  height = 20,
  stroke = '#667085',
  fill = 'none',
  ...props
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill={fill}
    {...props}>
    <path
      d='M9.99984 2.50008H13.4998C14.9 2.50008 15.6 2.50008 16.1348 2.77257C16.6052 3.01225 16.9877 3.3947 17.2274 3.86511C17.4998 4.39988 17.4998 5.09995 17.4998 6.50008V13.5001C17.4998 14.9002 17.4998 15.6003 17.2274 16.1351C16.9877 16.6055 16.6052 16.9879 16.1348 17.2276C15.6 17.5001 14.9 17.5001 13.4998 17.5001H6.49984C5.09971 17.5001 4.39964 17.5001 3.86486 17.2276C3.39446 16.9879 3.012 16.6055 2.77232 16.1351C2.49984 15.6003 2.49984 14.9002 2.49984 13.5001V10.0001M6.6665 10.8334V14.1667M13.3332 9.16675V14.1667M9.99984 5.83341V14.1667M1.6665 4.16675L4.1665 1.66675M4.1665 1.66675L6.6665 4.16675M4.1665 1.66675L4.1665 6.66675'
      stroke={stroke}
      strokeWidth='1.67'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default BarChartSquareUp
