export enum InputRuleKey {
  FileRequired,
  Required,
  Name,
  Username,
  Content,
  Text,
  Paragraph,
  Phone,
}

interface InputRule {
  [key: string]: unknown
  message?: string
  formatMessage?: (field: string, t: (...args: any[]) => string) => string
}

export const InputRuleMapping: Record<InputRuleKey, InputRule> = {
  [InputRuleKey.Required]: {
    required: true,
    formatMessage: (field: string, t): string =>
      t('Trường {{input}} bắt buộc', {
        input: t(field),
      }),
  },
  [InputRuleKey.FileRequired]: {
    required: true,
    formatMessage: (field: string, t): string =>
      t('Vui lòng tải lên một tệp {{input}} hợp lệ', {
        input: t(field),
      }),
  },
  [InputRuleKey.Name]: {
    min: 2,
    max: 512,
    formatMessage: (field: string, t): string =>
      t('Hãy nhập giá trị có từ 2 đến 512 ký tự hoặc ít hơn'),
  },
  [InputRuleKey.Username]: {
    pattern: new RegExp(/^[a-z0-9_\.]+$/),
    formatMessage: (field: string, t): string =>
      t('Tên người dùng chỉ có thể chứa các ký tự viết thường, số hoặc dấu gạch dưới'),
  },
  [InputRuleKey.Text]: {
    min: 1,
    max: 2000,
    formatMessage: (field: string, t): string =>
      t('Vui lòng nhập giá trị có tối đa {{max}} ký tự hoặc ít hơn', {
        max: 2000,
      }),
  },
  [InputRuleKey.Paragraph]: {
    min: 1,
    max: 4000,
    formatMessage: (field: string, t): string =>
      t('Hãy nhập giá trị có ít hơn {{max}} kí tự', {
        max: 4000,
      }),
  },
  [InputRuleKey.Phone]: {
    pattern: new RegExp(/^[0-9]{10}$/),
    formatMessage: (field: string, t): string =>
      t('Vui lòng nhập một {{input}} hợp lệ bắt đầu bằng 0', {
        input: t('số điện thoại'),
      }),
  },
}

export const getInputRule = (
  ruleKey: InputRuleKey,
  field: string,
  t: (value: string) => string,
): InputRule => {
  const rule = { ...InputRuleMapping[ruleKey] }
  if (!rule.formatMessage || rule.message) return rule
  rule.message = rule.formatMessage(field, t)
  delete rule.formatMessage
  return rule
}

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
