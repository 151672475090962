import { Button, Modal, ModalProps as AntModalProps, Typography } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { XClose } from './Icons'

export type CustomizedModalProps = AntModalProps & {
  title?: string
  description?: string
  okText?: string
  secondCancelText?: string
  hideOk?: boolean
  okButtonLoading?: boolean
  cancelButtonLoading?: boolean
  okDisabled?: boolean
  hideClose?: boolean
  icon?: React.ReactNode
  iconClassName?: string
  iconBackgroundColor?: string
  isDanger?: boolean
  onSecondCancel?: () => void
  onClose?: () => void
  onSuccess?: () => void
}

export const CustomizedModal: React.FC<CustomizedModalProps> = ({
  open,
  title,
  description,
  icon,
  iconBackgroundColor,
  okText,
  cancelText,
  secondCancelText,
  okButtonLoading,
  cancelButtonLoading,
  hideOk = false,
  hideClose = false,
  okDisabled = false,
  isDanger = false,
  onOk,
  onCancel,
  onClose,
  onSecondCancel,
  iconClassName = 'flex items-center justify-center my-6 w-12 h-12 p-3 rounded-3xl mx-auto',
  children,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      open={open}
      {...props}
      footer={null}
      centered={true}
      closeIcon={false}
      destroyOnClose={true}
      onCancel={onClose || onCancel}>
      {!hideClose && (
        <XClose
          width={20}
          height={20}
          stroke='#667085'
          className='absolute top-6 right-7 cursor-pointer'
          onClick={onClose || onCancel}
        />
      )}

      <Typography.Title className='text-center !mb-4' level={5}>
        {title || t('Are you sure?')}
      </Typography.Title>

      {icon && (
        <div className={iconClassName} style={{ backgroundColor: iconBackgroundColor }}>
          {icon}
        </div>
      )}

      {!!description && (
        <Typography.Text
          className={classNames('text-wedgewood-500 block whitespace-pre-wrap')}>
          {description}
        </Typography.Text>
      )}
      {children}

      {!hideOk && (
        <Button
          type='primary'
          className='w-full mt-4'
          danger={isDanger}
          onClick={onOk}
          loading={okButtonLoading}
          disabled={okDisabled}>
          {okText || t('OK')}
        </Button>
      )}

      {!!cancelText && (
        <Button className='w-full mt-4' onClick={onCancel} loading={cancelButtonLoading}>
          {cancelText}
        </Button>
      )}

      {!!secondCancelText && (
        <Button
          className='w-full mt-4'
          onClick={onSecondCancel}
          loading={cancelButtonLoading}>
          {secondCancelText}
        </Button>
      )}
    </Modal>
  )
}
