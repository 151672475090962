import React, { SVGProps } from 'react'

const SearchMd: React.FC<SVGProps<SVGSVGElement>> = ({
  width = 24,
  height = 24,
  stroke = '#667085',
  fill = 'none',
  ...props
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    fill={fill}
    viewBox='0 0 24 24'
    {...props}>
    <path
      stroke={stroke}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='m21 21-4.35-4.35M19 11a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z'
    />
  </svg>
)

export default SearchMd
