import LocalItem, { LocalItemDataType, LocalItemKey } from '@/utilities/localStorage'

export const authLocalItem = new LocalItem(LocalItemKey.Auth, LocalItemDataType.JSON)

export const activeReportsLocalItem = new LocalItem(
  LocalItemKey.ActiveReports,
  LocalItemDataType.JSON,
)

export const surveyResponsesLocalItem = new LocalItem(
  LocalItemKey.SurveyResponses,
  LocalItemDataType.JSON,
)

export const reviewsLocalItem = new LocalItem(
  LocalItemKey.Reviews,
  LocalItemDataType.JSON,
)

export const topicAnalysisColumnsLocalItem = new LocalItem(
  LocalItemKey.TopicAnalysisColumns,
  LocalItemDataType.JSON,
)

export const actionFormLocalItem = new LocalItem(
  LocalItemKey.ActionForm,
  LocalItemDataType.JSON,
)

export const segmentFormLocalItem = new LocalItem(
  LocalItemKey.SegmentForm,
  LocalItemDataType.JSON,
)

export const campaignFormLocalItem = new LocalItem(
  LocalItemKey.CampaignForm,
  LocalItemDataType.JSON,
)

export const ticketTabsLocalItem = new LocalItem(
  LocalItemKey.TicketTabs,
  LocalItemDataType.JSON,
)

export const deleteAllLocalItems = (logout = true): void => {
  logout && authLocalItem.delete()
  actionFormLocalItem.delete()
  campaignFormLocalItem.delete()
}
