export enum TimeUnit {
  Minute = 'minute',
  Hour = 'hour',
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export const timeUnits = Object.values(TimeUnit)

export interface TimeRange {
  start: string
  end: string
  unit?: TimeUnit
}

export type TimeWindow = {
  length: number
  unit: TimeUnit | null
  is_current?: boolean
}

export interface Time {
  range: TimeRange
  window?: TimeWindow
}
