import { Input, Table, TableProps, Tag, Typography } from 'antd'
import classNames from 'classnames'
import React, { ReactNode } from 'react'

import { SearchMd } from './Icons'

export type CustomizedTableProps = Omit<TableProps<any>, 'title'> & {
  title?: string
  extra?: ReactNode
  totalUnit?: string
  searchPlaceHolder?: string
  filter?: React.ReactNode
  onSearchChange?: (e: { target: HTMLInputElement }) => void
  className?: string
  hideHeader?: boolean
  hideSearchFilter?: boolean
}

export const CustomizedTable: React.FC<CustomizedTableProps> = ({
  extra,
  totalUnit,
  title = '',
  searchPlaceHolder = '',
  onSearchChange,
  filter,
  className,
  hideHeader = false,
  hideSearchFilter = false,
  ...props
}) => {
  const { pagination } = props

  const renderSearchAndFilters = (showHeader: boolean) => {
    return (
      <div
        className={classNames(
          !showHeader && 'pt-6',
          'px-6 pb-4 bg-white flex flex-wrap gap-4',
        )}>
        {!!onSearchChange && (
          <Input
            id='search-input'
            prefix={<SearchMd />}
            className='flex-1'
            placeholder={searchPlaceHolder}
            onChange={onSearchChange}
          />
        )}
        {!!filter && filter}
      </div>
    )
  }

  const showHeader = (!!title || !!extra) && !hideHeader

  return (
    <div className={classNames('ant-card overflow-hidden w-full', className)}>
      {showHeader && (
        <div className='pl-6 pt-6 pb-4 pr-4 bg-white border-none flex items-center gap-2'>
          <Typography.Title level={5}>{title}</Typography.Title>
          {!!pagination && (
            <Tag className='px-2 py-0.5 font-medium text-xs rounded-2xl' color='blue'>
              {pagination?.total} {totalUnit}
            </Tag>
          )}

          {!!extra && <div className='ml-auto'>{extra}</div>}
        </div>
      )}

      {!hideSearchFilter &&
        (!!onSearchChange || !!filter) &&
        renderSearchAndFilters(showHeader)}

      <Table
        className={classNames(
          hideSearchFilter && !showHeader && '[&_.ant-table]:!border-t-0',
          'w-full',
        )}
        {...props}
        pagination={
          pagination && {
            ...pagination,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }
        }
      />
    </div>
  )
}
