import { Button, Divider, Tag, Typography } from 'antd'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Calendar, Plus } from './Icons'

export const ComingSoonTag: React.FC = ({ children }) => {
  const { t } = useTranslation()
  return (
    <Tag
      className='h-5 px-2 py-px border-none mr-0 bg-gray-100 rounded-3xl gap-2.5'
      color='blue'>
      <div className='text-wedgewood-500 text-xs font-semibold'>
        {children || t('Coming soon')}
      </div>
    </Tag>
  )
}

interface Props {
  screenshotUrls?: string[]
  title?: string
  subtitle?: string
  descriptions?: string
  icon?: React.ReactNode
}

export const ComingSoon: React.FC<Props> = ({
  screenshotUrls,
  subtitle = '',
  title = '',
  descriptions = '',
  icon,
}) => {
  const { t } = useTranslation()

  return (
    <>
      {(title || subtitle) && (
        <div className='flex gap-4 items-end'>
          <div>
            {!!title && (
              <Typography.Title level={3} className='font-semibold !mb-2'>
                {t(title)}
              </Typography.Title>
            )}
            {!!subtitle && (
              <Typography.Text className='text-wedgewood-500'>
                {t(subtitle)}
              </Typography.Text>
            )}
          </div>

          <div className='ml-auto'>
            <Button icon={<Calendar stroke='currentColor' />}>{t('Last 7 days')}</Button>
          </div>
          <Button type='primary' icon={<Plus width='20' height='20' stroke='#fff' />}>
            {t('Add source')}
          </Button>
        </div>
      )}

      <Divider className='mb-0 -mx-6' />

      <div className='relative'>
        {!!screenshotUrls?.length &&
          screenshotUrls.map(url => (
            <img key={url} src={url} alt='screenshot' className='w-full' />
          ))}

        <div
          className='absolute top-0 left-0 w-full h-full'
          style={{
            background: 'linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)',
            backdropFilter: 'blur(1px)',
          }}>
          <div className='w-1/2 p-12 flex flex-col gap-6'>
            <div className='flex gap-2'>
              {icon}
              <Typography.Title level={3} className='font-semibold !mb-0'>
                {t(title)}
              </Typography.Title>
            </div>
            {!!descriptions && (
              <Typography.Paragraph>
                <Trans>{t(descriptions)}</Trans>
              </Typography.Paragraph>
            )}
            <Button
              className='bg-blue-50 text-black-pearl-500 border-black-pearl-200'
              onClick={(window as any).FB?.CustomerChat.showDialog}>
              {t('Contact our Customer Support')}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
