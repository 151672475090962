import { BaseOptionType } from 'antd/es/select'

export const getLocale = (locale: string): 'vi' | 'en' | 'cn' => {
  return locale.includes('vi') ? 'vi' : locale.includes('en') ? 'en' : 'cn'
}

export const translateOptions = (
  options: BaseOptionType[],
  t: (...args: any[]) => string,
): BaseOptionType[] => {
  return options.map(option => ({
    ...option,
    label: t(option.label),
  }))
}
