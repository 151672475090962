import {
  MetricType,
  QuestionType,
  SurveyFormField,
  SurveyScreenFormField,
} from '../types/question'

export const DEFAULT_QUERY_PARAMS = new Set(['user_id', 'source', 'utm_medium', 'score'])

export const ratingQuestionTypes = [QuestionType.Csat, QuestionType.Ces, QuestionType.NPS]

export const mainCommentQuestionTypes = [
  QuestionType.Text,
  QuestionType.Checkbox,
  QuestionType.MultipleChoice,
]

export const ratingRangeMappings = {
  [QuestionType.Csat]: [1, 5],
  [QuestionType.Ces]: [1, 7],
  [QuestionType.NPS]: [0, 10],
}

export const SurveyScreenDefaultValues = {
  [SurveyFormField.WelcomeScreen]: {
    [SurveyScreenFormField.Message]:
      'Vui lòng dành một vài phút để chia sẻ về trải nghiệm của bạn',
    [SurveyScreenFormField.Button]: 'Bắt đầu',
    [SurveyScreenFormField.Enabled]: false,
  },
  [SurveyFormField.InfoScreen]: {
    [SurveyScreenFormField.Title]: 'Còn 1 bước nữa!',
    [SurveyScreenFormField.Message]:
      'Đóng góp của bạn là rất quan trọng trong việc cải thiện dịch vụ của chúng tôi! Vui lòng chia sẻ thông tin của bạn để chúng tôi có thể liên lạc với bạn.',
    [SurveyScreenFormField.Enabled]: true,
    [SurveyScreenFormField.Inputs]: [
      {
        id: 'USER_NAME',
        title: 'Họ và tên',
        type: 'text',
        required: false,
      },
      {
        id: 'USER_PHONE',
        title: 'Số điện thoại',
        type: 'text',
        required: false,
      },
      {
        id: 'USER_EMAIL',
        title: 'Địa chỉ email',
        type: 'text',
        required: false,
      },
    ],
  },
  [SurveyFormField.CompletionScreen]: {
    [SurveyScreenFormField.Title]: 'Cảm ơn bạn đã phản hồi!',
    [SurveyScreenFormField.Message]:
      'Chúng tôi rất trân trọng phản hồi của bạn! Bạn đã góp phần giúp sản phẩm và dịch vụ của chúng tôi ngày càng tốt hơn.',
  },
  [SurveyFormField.ExpirationScreen]: {
    [SurveyScreenFormField.Title]: 'Khảo sát đã hết hạn',
    [SurveyScreenFormField.Message]:
      'Khảo sát này đã kết thúc và không nhận phản hồi mới. Cảm ơn bạn đã quan tâm.',
  },
}

const SentimentColor = {
  Negative: '#F97066',
  Neutral: '#FAC515',
  Positive: '#66C61C',
}

export const NPSColorMapping = {
  0: SentimentColor.Negative,
  1: SentimentColor.Negative,
  2: SentimentColor.Negative,
  3: SentimentColor.Negative,
  4: SentimentColor.Negative,
  5: SentimentColor.Negative,
  6: SentimentColor.Negative,
  7: SentimentColor.Neutral,
  8: SentimentColor.Neutral,
  9: SentimentColor.Positive,
  10: SentimentColor.Positive,
}

export const CSATColorMapping = {
  1: SentimentColor.Negative,
  2: SentimentColor.Negative,
  3: SentimentColor.Neutral,
  4: SentimentColor.Positive,
  5: SentimentColor.Positive,
}

export const CESColorMapping = {
  1: SentimentColor.Negative,
  2: SentimentColor.Negative,
  3: SentimentColor.Negative,
  4: SentimentColor.Neutral,
  5: SentimentColor.Neutral,
  6: SentimentColor.Positive,
  7: SentimentColor.Positive,
}

enum CsatGroups {
  POSITIVE = 'Positive',
  NEUTRAL = 'Neutral',
  NEGATIVE = 'Negative',
}

enum CesGroups {
  LOW_EFFORT = 'Low effort',
  MED_EFFORT = 'Medium effort',
  HIGH_EFFORT = 'High effort',
}

enum NpsGroups {
  PROMOTERS = 'Promoters',
  PASSIVES = 'Passives',
  DETRACTORS = 'Detractors',
}

type CXMetricGroup = {
  description: string
  label: string
  min: number
  max: number
}

type CsatGroup = {
  [key in CsatGroups]: CXMetricGroup
}

type CesGroup = {
  [key in CesGroups]: CXMetricGroup
}

type NpsGroup = {
  [key in NpsGroups]: CXMetricGroup
}

type CXMetricGroups = CsatGroup | CesGroup | NpsGroup

export const CXMetricGroupMappings: Record<MetricType, CXMetricGroups> = {
  [MetricType.Csat]: {
    [CsatGroups.POSITIVE]: {
      label: CsatGroups.POSITIVE,
      description: 'Responses with positive sentiment about their experience.',
      min: 4,
      max: 5,
    },
    [CsatGroups.NEUTRAL]: {
      label: CsatGroups.NEUTRAL,
      description: 'Responses with neutral sentiment about their experience.',
      min: 3,
      max: 3,
    },
    [CsatGroups.NEGATIVE]: {
      label: CsatGroups.NEGATIVE,
      description: 'Responses with negative sentiment about their experience.',
      min: 1,
      max: 2,
    },
  },
  [MetricType.Nps]: {
    [NpsGroups.PROMOTERS]: {
      label: NpsGroups.PROMOTERS,
      description: 'Responses that highly rate and likely to recommend your company.',
      min: 9,
      max: 10,
    },
    [NpsGroups.PASSIVES]: {
      label: NpsGroups.PASSIVES,
      description: 'Responses with a neutral rating of your company.',
      min: 7,
      max: 8,
    },
    [NpsGroups.DETRACTORS]: {
      label: NpsGroups.DETRACTORS,
      description: 'Unsatisfied responses likely to criticize your company.',
      min: 0,
      max: 6,
    },
  },
  [MetricType.Ces]: {
    [CesGroups.LOW_EFFORT]: {
      label: CesGroups.LOW_EFFORT,
      description:
        'Responses that found it easy to interact with your company specific touchpoint.',
      min: 5.5,
      max: 7,
    },
    [CesGroups.MED_EFFORT]: {
      label: CesGroups.MED_EFFORT,
      description:
        'Responses that had a moderate effort interacting with your specific touchpoint.',
      min: 4,
      max: 5,
    },
    [CesGroups.HIGH_EFFORT]: {
      label: CesGroups.HIGH_EFFORT,
      description:
        'Responses that experienced high effort in interacting with your company.',
      min: 1,
      max: 3,
    },
  },
}

export const MatrixFieldNameRegex = /(\d+-\d+)/ // {questionId}-{rowId}
