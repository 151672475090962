import dayjs, { Dayjs as DayjsInterface } from 'dayjs'
import duration from 'dayjs/plugin/duration'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'

dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat)

// Dayjs format
// Short form datetime should be used inside tables or modals
// L
export const DAYJS_FORMAT = {
  DEFAULT: '',
  SPECIFIED_TIME: 'hh:mm:ss A', // 10:42:12 AM
  HOUR: 'HH:mm', // 14:24
  TIME: 'LT', // 8:02 PM
  LONG_DATE_WITH_SHORTEN_WEEKDAY: 'ddd, LL', // Fri, January 19, 2023
  LONG_DATE_WITH_WEEKDAY: 'dddd, LL', // Friday, January 19, 2023
  LONG_DATE: 'LL', //	August 16, 2018
  LONG_DATE_TIME: 'LLL', // August 16, 2018 8:02 PM
  ISO_DATE: 'YYYY-MM-DD', // 2023-07-21
  SHORT_DATE: 'DD-MM-YYYY', // 20/08/2022
  SHORT_DAY_AND_MONTH: 'MMM D', // Aug 16
  SHORT_DAY_MONTH_TIME: 'MMM D HH:mm', // Aug 16 14:24
  SHORT_DAY_MONTH_YEAR: 'll', // Aug 16, 2018
  SHORT_MONTH_AND_YEAR: 'MMM YYYY', // Aug 2018
  SHORT_DATE_TIME: 'lll', // Aug 16, 2018 8:02 PM,
  YEAR: 'YYYY', // 2018
}

export default dayjs
export type Dayjs = DayjsInterface
