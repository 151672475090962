import React, { SVGProps } from 'react'

const BarChart12: React.FC<SVGProps<SVGSVGElement>> = ({
  width = 20,
  height = 20,
  stroke = '#667085',
  fill = 'none',
  ...props
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill={fill}
    {...props}>
    <path
      d='M7.5 10H3.83333C3.36662 10 3.13327 10 2.95501 10.0908C2.79821 10.1707 2.67072 10.2982 2.59083 10.455C2.5 10.6333 2.5 10.8666 2.5 11.3333V16.1667C2.5 16.6334 2.5 16.8667 2.59083 17.045C2.67072 17.2018 2.79821 17.3293 2.95501 17.4092C3.13327 17.5 3.36662 17.5 3.83333 17.5H7.5M7.5 17.5H12.5M7.5 17.5L7.5 7.16667C7.5 6.69996 7.5 6.4666 7.59083 6.28834C7.67072 6.13154 7.79821 6.00406 7.95501 5.92416C8.13327 5.83333 8.36662 5.83333 8.83333 5.83333H11.1667C11.6334 5.83333 11.8667 5.83333 12.045 5.92416C12.2018 6.00406 12.3293 6.13154 12.4092 6.28834C12.5 6.4666 12.5 6.69996 12.5 7.16667V17.5M12.5 17.5H16.1667C16.6334 17.5 16.8667 17.5 17.045 17.4092C17.2018 17.3293 17.3293 17.2018 17.4092 17.045C17.5 16.8667 17.5 16.6334 17.5 16.1667V3.83333C17.5 3.36662 17.5 3.13327 17.4092 2.95501C17.3293 2.79821 17.2018 2.67072 17.045 2.59083C16.8667 2.5 16.6334 2.5 16.1667 2.5H13.8333C13.3666 2.5 13.1333 2.5 12.955 2.59083C12.7982 2.67072 12.6707 2.79821 12.5908 2.95501C12.5 3.13327 12.5 3.36662 12.5 3.83333V6.66667'
      stroke={stroke}
      strokeWidth='1.67'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default BarChart12
