import React, { createContext, useContext, useState } from 'react'
import { isEmptyObject } from 'utilities/src/main'

export enum Language {
  Vietnamese = 'vi',
  English = 'en',
}

const DEFAULT_RESOURCE = {
  ['en-US']: {
    'Câu hỏi': 'Question',
    Trang: 'Page',
    'Rất dễ dàng': 'Very easy',
    'Rất không hài lòng': 'Very dissatisfied',
    'Rất khó khăn': 'Very difficult',
    'Rất hài lòng': 'Very satisfied',
    'Không có khả năng': 'Not at all likely',
    'Rất có khả năng': 'Extremely likely',
    'Câu trả lời của bạn': 'Your answer',
    'Gửi phản hồi': 'Submit',
    'Bỏ qua': 'Skip',
    'Bạn có thể chọn một hoặc nhiều câu trả lời': 'You can select one or more answers',
    'Bạn có thể chọn tối đa {{max_selections}} câu trả lời':
      'You can select up to {{max_selections}} answers',
    'Vui lòng nhập thông tin': 'Please enter information',
    'Cung cấp bởi': 'Powered by',
    'câu trả lời': 'answer',
    'Vui lòng nhập {{input}} hợp lệ': 'Please input valid {{input}}',
    'Tiếp tục': 'Continue',
    'Quay lại': 'Back',
    'Ngôn ngữ': 'Language',
    'Bạn đã hoàn thành khảo sát này': 'You have already completed the survey.',
    'Ý kiến của bạn giúp ích cho chúng tôi rất nhiều. Cảm ơn bạn đã dành thời gian thực hiện khảo sát!':
      'Your feedback helps a lot. Thank you very much for participating in our survey.',
  },
  ['en-UK']: {
    'Câu hỏi': 'Question',
    Trang: 'Page',
    'Rất dễ dàng': 'Very easy',
    'Rất không hài lòng': 'Very dissatisfied',
    'Rất khó khăn': 'Very difficult',
    'Rất hài lòng': 'Very satisfied',
    'Không có khả năng': 'Not at all likely',
    'Rất có khả năng': 'Extremely likely',
    'Câu trả lời của bạn': 'Your answer',
    'Gửi phản hồi': 'Submit',
    'Bỏ qua': 'Skip',
    'Bạn có thể chọn một hoặc nhiều câu trả lời': 'You can select one or more answers',
    'Bạn có thể chọn tối đa {{max_selections}} câu trả lời':
      'You can select up to {{max_selections}} answers',
    'Vui lòng nhập thông tin': 'Please enter information',
    'Cung cấp bởi': 'Powered by',
    'câu trả lời': 'answer',
    'Vui lòng nhập {{input}} hợp lệ': 'Please input valid {{input}}',
    'Tiếp tục': 'Continue',
    'Quay lại': 'Back',
    'Ngôn ngữ': 'Language',
    'Bạn đã hoàn thành khảo sát này': 'You have already completed the survey.',
    'Ý kiến của bạn giúp ích cho chúng tôi rất nhiều. Cảm ơn bạn đã dành thời gian thực hiện khảo sát!':
      'Your feedback helps a lot. Thank you very much for participating in our survey.',
  },
  ['ko']: {
    'Câu hỏi': '질문',
    'Rất dễ dàng': '매우 쉬움',
    'Rất không hài lòng': '매우 불만족',
    'Rất khó khăn': '매우 어려움',
    'Rất hài lòng': '매우 만족',
    'Không có khả năng': '전혀 가능성 없음',
    'Rất có khả năng': '매우 가능성 높음',
    'Câu trả lời của bạn': '당신의 답변',
    'Gửi phản hồi': '제출',
    'Bỏ qua': '건너뛰기',
    'Bạn có thể chọn một hoặc nhiều câu trả lời': '하나 이상의 답변을 선택할 수 있습니다',
    'Bạn có thể chọn tối đa {{max_selections}} câu trả lời':
      '{{max_selections}}개의 답변을 선택할 수 있습니다',
    'Vui lòng nhập thông tin': '정보를 입력해주세요',
    'Cung cấp bởi': '제공',
    'câu trả lời': '답변',
    'Vui lòng nhập {{input}} hợp lệ': '{{input}}을(를) 입력해주세요',
    'Tiếp tục': '계속',
    'Quay lại': '뒤쪽에',
    'Ngôn ngữ': '언어',
    'Bạn đã hoàn thành khảo sát này': '이 설문 조사를 이미 완료했습니다.',
    'Ý kiến của bạn giúp ích cho chúng tôi rất nhiều. Cảm ơn bạn đã dành thời gian thực hiện khảo sát!':
      '피드백은 매우 중요합니다. 설문 조사에 참여해 주셔서 감사합니다.',
  },
  ['ja']: {
    'Câu hỏi': '質問',
    'Rất dễ dàng': 'とても簡単',
    'Rất không hài lòng': 'とても不満',
    'Rất khó khăn': 'とても難しい',
    'Rất hài lòng': 'とても満足',
    'Không có khả năng': '全く可能性なし',
    'Rất có khả năng': 'とても可能性が高い',
    'Câu trả lời của bạn': 'あなたの答え',
    'Gửi phản hồi': '提出する',
    'Bỏ qua': 'スキップ',
    'Bạn có thể chọn một hoặc nhiều câu trả lời': '1つ以上の答えを選択できます',
    'Bạn có thể chọn tối đa {{max_selections}} câu trả lời':
      '{{max_selections}}個の答えを選択できます',
    'Vui lòng nhập thông tin': '情報を入力してください',
    'Cung cấp bởi': '提供',
    'câu trả lời': '答え',
    'Vui lòng nhập {{input}} hợp lệ': '{{input}}を入力してください',
    'Tiếp tục': '続行する',
    'Quay lại': '戻る',
    'Ngôn ngữ': '言語',
    'Bạn đã hoàn thành khảo sát này': 'このアンケートはすでに完了しました。',
    'Ý kiến của bạn giúp ích cho chúng tôi rất nhiều。 Cảm ơn bạn đã dành thời gian thực hiện khảo sát!':
      'フィードバックは非常に重要です。 アンケートにご参加いただきありがとうございます。',
  },
  ['fr']: {
    'Câu hỏi': 'Question',
    'Rất dễ dàng': 'Très facile',
    'Rất không hài lòng': 'Très insatisfait',
    'Rất khó khăn': 'Très difficile',
    'Rất hài lòng': 'Très satisfait',
    'Không có khả năng': 'Pas du tout probable',
    'Rất có khả năng': 'Extrêmement probable',
    'Câu trả lời của bạn': 'Votre réponse',
    'Gửi phản hồi': 'Soumettre',
    'Bỏ qua': 'Passer',
    'Bạn có thể chọn một hoặc nhiều câu trả lời':
      'Vous pouvez sélectionner une ou plusieurs réponses',
    'Bạn có thể chọn tối đa {{max_selections}} câu trả lời':
      "Vous pouvez sélectionner jusqu'à {{max_selections}} réponses",
    'Vui lòng nhập thông tin': 'Veuillez saisir des informations',
    'Cung cấp bởi': 'Propulsé par',
    'câu trả lời': 'réponse',
    'Vui lòng nhập {{input}} hợp lệ': 'Veuillez saisir un {{input}} valide',
    'Tiếp tục': 'Continuer',
    'Quay lại': 'Retourner',
    'Ngôn ngữ': 'Langue',
    'Bạn đã hoàn thành khảo sát này': 'Vous avez déjà terminé le sondage.',
    'Ý kiến của bạn giúp ích cho chúng tôi rất nhiều. Cảm ơn bạn đã dành thời gian thực hiện khảo sát!':
      'Votre avis nous aide beaucoup. Merci beaucoup d’avoir participé à notre enquête.',
  },
  ['zh']: {
    'Câu hỏi': '问题',
    'Rất dễ dàng': '非常容易',
    'Rất không hài lòng': '非常不满意',
    'Rất khó khăn': '非常困难',
    'Rất hài lòng': '非常满意',
    'Không có khả năng': '根本不可能',
    'Rất có khả năng': '极有可能',
    'Câu trả lời của bạn': '你的回答',
    'Gửi phản hồi': '提交',
    'Bỏ qua': '跳过',
    'Bạn có thể chọn một hoặc nhiều câu trả lời': '您可以选择一个或多个答案',
    'Bạn có thể chọn tối đa {{max_selections}} câu trả lời':
      '您最多可以选择{{max_selections}}个答案',
    'Vui lòng nhập thông tin': '请输入信息',
    'Cung cấp bởi': '提供',
    'câu trả lời': '答案',
    'Vui lòng nhập {{input}} hợp lệ': '请输入有效的{{input}}',
    'Tiếp tục': '继续',
    'Quay lại': '回去',
    'Ngôn ngữ': '语言',
    'Bạn đã hoàn thành khảo sát này': '您已完成此调查。',
    'Ý kiến của bạn giúp ích cho chúng tôi rất nhiều。 Cảm ơn bạn đã dành thời gian thực hiện khảo sát!':
      '您的反馈对我们非常重要。 非常感谢您参与我们的调查。',
  },
  ['zh-CN']: {
    'Câu hỏi': '问题',
    'Rất dễ dàng': '非常容易',
    'Rất không hài lòng': '非常不满意',
    'Rất khó khăn': '非常困难',
    'Rất hài lòng': '非常满意',
    'Không có khả năng': '根本不可能',
    'Rất có khả năng': '极有可能',
    'Câu trả lời của bạn': '你的回答',
    'Gửi phản hồi': '提交',
    'Bỏ qua': '跳过',
    'Bạn có thể chọn một hoặc nhiều câu trả lời': '您可以选择一个或多个答案',
    'Bạn có thể chọn tối đa {{max_selections}} câu trả lời':
      '您最多可以选择{{max_selections}}个答案',
    'Vui lòng nhập thông tin': '请输入信息',
    'Cung cấp bởi': '提供',
    'câu trả lời': '答案',
    'Vui lòng nhập {{input}} hợp lệ': '请输入有效的{{input}}',
    'Tiếp tục': '继续',
    'Quay lại': '回去',
    'Ngôn ngữ': '语言',
    'Bạn đã hoàn thành khảo sát này': '您已完成此调查。',
    'Ý kiến của bạn giúp ích cho chúng tôi rất nhiều。 Cảm ơn bạn đã dành thời gian thực hiện khảo sát!':
      '您的反馈对我们非常重要。 非常感谢您参与我们的调查。',
  },
  ['vi']: {
    'Câu hỏi': 'Câu hỏi',
    Trang: 'Trang',
    'Rất dễ dàng': 'Rất dễ dàng',
    'Rất không hài lòng': 'Rất không hài lòng',
    'Rất khó khăn': 'Rất khó khăn',
    'Rất hài lòng': 'Rất hài lòng',
    'Không có khả năng': 'Không có khả năng',
    'Rất có khả năng': 'Rất có khả năng',
    'Câu trả lời của bạn': 'Câu trả lời của bạn',
    'Gửi phản hồi': 'Gửi phản hồi',
    'Bỏ qua': 'Bỏ qua',
    'Bạn có thể chọn một hoặc nhiều câu trả lời':
      'Bạn có thể chọn một hoặc nhiều câu trả lời',
    'Bạn có thể chọn tối đa {{max_selections}} câu trả lời':
      'Bạn có thể chọn tối đa {{max_selections}} câu trả lời',
    'Vui lòng nhập thông tin': 'Vui lòng nhập thông tin',
    'Cung cấp bởi': 'Cung cấp bởi',
    'câu trả lời': 'câu trả lời',
    'Vui lòng nhập {{input}} hợp lệ': 'Vui lòng nhập {{input}} hợp lệ',
    'Tiếp tục': 'Tiếp tục',
    'Quay lại': 'Quay lại',
    'Ngôn ngữ': 'Ngôn ngữ',
    'Bạn đã hoàn thành khảo sát này': 'Bạn đã hoàn thành khảo sát này',
    'Ý kiến của bạn giúp ích cho chúng tôi rất nhiều. Cảm ơn bạn đã dành thời gian thực hiện khảo sát!':
      'Ý kiến của bạn giúp ích cho chúng tôi rất nhiều. Cảm ơn bạn đã dành thời gian thực hiện khảo sát!',
  },
}

type TSurveyLanguageContext =
  | {
      languageOptions?: { key: string; flag: string }[]
      setLanguageOptions: (languages: { key: string; flag: string }[]) => void
      resources: Record<string, any> | undefined
      addResource: (languageCode: string, resource: Record<string, string>) => void
      language?: string
      changeLanguage: (newLanguage: string) => void
      translate: (text: string) => string
    }
  | undefined

export const LanguageContext = createContext<TSurveyLanguageContext>(undefined)

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState<string>()
  const [resources, setResources] = useState<Record<string, any>>({})
  const [languageOptions, setLanguageOptions] = useState<any[]>()

  const changeLanguage = newLanguage => {
    setLanguage(newLanguage)
  }

  const addResource = (languageCode: string, resource: Record<string, string>) => {
    setResources(prev => ({
      ...prev,
      [languageCode]: { ...DEFAULT_RESOURCE[languageCode], ...resource },
    }))
  }

  const translate = text => {
    if (!language) return text
    if (!resources?.[language]) return text
    const translatedText = resources?.[language]?.[text]
    return translatedText
  }

  return (
    <LanguageContext.Provider
      value={{
        languageOptions,
        setLanguageOptions,
        language,
        changeLanguage,
        addResource,
        resources,
        translate,
      }}>
      {children}
    </LanguageContext.Provider>
  )
}

export const useTranslateText = () => {
  const languageContext = useContext(LanguageContext)

  const translateText = (text, options?) => {
    if (!text) return ''
    if (!languageContext?.translate) return text
    let translatedText = languageContext.translate(text)

    if (!!options && !isEmptyObject(options)) {
      Object.entries(options).forEach(([key, value]) => {
        translatedText = translatedText?.replace(`{{${key}}}`, `${value}`)
      })
    }

    return translatedText
  }

  return translateText
}
