import * as React from 'react'

const Logout02: React.FC<React.SVGProps<SVGSVGElement>> = ({
  width = 20,
  height = 20,
  fill = 'none',
  stroke = '#667085',
  ...props
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill={fill}
      viewBox='0 0 20 20'
      {...props}>
      <path
        stroke={stroke}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.67}
        d='M6.667 5.833 2.5 10m0 0 4.167 4.167M2.5 10h10M10 5.833c0-.775 0-1.162.085-1.48a2.5 2.5 0 0 1 1.768-1.768c.318-.085.705-.085 1.48-.085h.417c1.165 0 1.747 0 2.207.19a2.5 2.5 0 0 1 1.353 1.353c.19.46.19 1.042.19 2.207v7.5c0 1.165 0 1.747-.19 2.207a2.5 2.5 0 0 1-1.353 1.353c-.46.19-1.042.19-2.207.19h-.417c-.775 0-1.162 0-1.48-.085a2.5 2.5 0 0 1-1.768-1.768C10 15.329 10 14.942 10 14.167'
      />
    </svg>
  )
}

export default Logout02
