import { Skeleton } from 'antd'
import classNames from 'classnames'
import React from 'react'

interface Props {
  count?: number
  direction?: 'vertical' | 'horizontal'
  rows?: number
  wrapperClassName?: string
  className?: string
}

const Skeletons: React.FC<Props> = ({
  count = 3,
  direction = 'vertical',
  rows = 2,
  wrapperClassName,
  className,
}) => (
  <div
    className={classNames(
      'flex gap-6',
      direction === 'vertical' && 'flex-col',
      wrapperClassName,
    )}>
    {[...Array(count)].map((_, idx) => (
      <Skeleton
        key={idx}
        active
        paragraph={{ rows }}
        rootClassName={classNames('p-6 rounded-none', className)}
      />
    ))}
  </div>
)

export default Skeletons
