import * as React from 'react'

const Star01: React.FC<React.SVGProps<SVGSVGElement>> = ({
  width = 40,
  height = 40,
  stroke = '#FAC515',
  fill = 'none',
  ...props
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 40 40'
    fill={fill}
    {...props}>
    <path
      opacity='0.12'
      d='M18.8046 5.75555C19.1887 4.97732 19.3808 4.5882 19.6415 4.46388C19.8684 4.35571 20.132 4.35571 20.3588 4.46388C20.6196 4.5882 20.8117 4.97732 21.1958 5.75555L24.8403 13.1388C24.9537 13.3686 25.0104 13.4835 25.0932 13.5726C25.1666 13.6516 25.2546 13.7156 25.3524 13.7611C25.4628 13.8124 25.5895 13.8309 25.843 13.868L33.9951 15.0595C34.8536 15.185 35.2828 15.2477 35.4815 15.4574C35.6543 15.6398 35.7356 15.8905 35.7027 16.1396C35.6649 16.426 35.3541 16.7287 34.7326 17.334L28.8359 23.0774C28.6521 23.2564 28.5602 23.3459 28.5009 23.4525C28.4484 23.5468 28.4147 23.6504 28.4017 23.7575C28.387 23.8786 28.4087 24.005 28.4521 24.2579L29.8434 32.3702C29.9902 33.2258 30.0636 33.6536 29.9257 33.9075C29.8057 34.1284 29.5924 34.2833 29.3453 34.3291C29.0612 34.3818 28.677 34.1798 27.9087 33.7757L20.6208 29.9431C20.3937 29.8237 20.2802 29.764 20.1606 29.7405C20.0547 29.7197 19.9457 29.7197 19.8398 29.7405C19.7202 29.764 19.6067 29.8237 19.3796 29.9431L12.0917 33.7757C11.3234 34.1798 10.9392 34.3818 10.6551 34.3291C10.408 34.2833 10.1947 34.1284 10.0747 33.9075C9.93683 33.6536 10.0102 33.2258 10.157 32.3702L11.5483 24.2579C11.5917 24.005 11.6134 23.8786 11.5987 23.7575C11.5857 23.6504 11.552 23.5468 11.4995 23.4525C11.4402 23.3459 11.3483 23.2564 11.1645 23.0774L5.26776 17.334C4.64627 16.7287 4.33552 16.426 4.29771 16.1396C4.26481 15.8905 4.34609 15.6398 4.51891 15.4574C4.71755 15.2477 5.14678 15.185 6.00524 15.0595L14.1573 13.868C14.4109 13.8309 14.5376 13.8124 14.648 13.7611C14.7458 13.7156 14.8338 13.6516 14.9071 13.5726C14.99 13.4835 15.0467 13.3686 15.1601 13.1388L18.8046 5.75555Z'
      fill='#fff'
    />
    <path
      d='M18.8046 5.75549C19.1887 4.97725 19.3808 4.58814 19.6415 4.46382C19.8684 4.35565 20.132 4.35565 20.3588 4.46382C20.6196 4.58814 20.8117 4.97725 21.1958 5.75549L24.8403 13.1388C24.9537 13.3685 25.0104 13.4834 25.0932 13.5726C25.1666 13.6516 25.2546 13.7155 25.3524 13.761C25.4628 13.8123 25.5895 13.8309 25.843 13.8679L33.9952 15.0595C34.8536 15.1849 35.2828 15.2477 35.4815 15.4574C35.6543 15.6398 35.7356 15.8905 35.7027 16.1396C35.6649 16.4259 35.3541 16.7286 34.7326 17.3339L28.8359 23.0773C28.6521 23.2564 28.5602 23.3459 28.5009 23.4524C28.4484 23.5467 28.4147 23.6503 28.4017 23.7575C28.387 23.8785 28.4087 24.0049 28.4521 24.2579L29.8434 32.3702C29.9902 33.2258 30.0636 33.6536 29.9257 33.9075C29.8057 34.1283 29.5924 34.2833 29.3453 34.3291C29.0612 34.3817 28.677 34.1797 27.9087 33.7756L20.6208 29.943C20.3937 29.8236 20.2802 29.7639 20.1606 29.7404C20.0547 29.7197 19.9457 29.7197 19.8398 29.7404C19.7202 29.7639 19.6067 29.8236 19.3796 29.943L12.0917 33.7756C11.3234 34.1797 10.9392 34.3817 10.6551 34.3291C10.408 34.2833 10.1947 34.1283 10.0747 33.9075C9.93683 33.6536 10.0102 33.2258 10.157 32.3702L11.5483 24.2579C11.5917 24.0049 11.6134 23.8785 11.5987 23.7575C11.5857 23.6503 11.552 23.5467 11.4995 23.4524C11.4402 23.3459 11.3483 23.2564 11.1645 23.0773L5.26776 17.3339C4.64627 16.7286 4.33552 16.4259 4.29771 16.1396C4.26481 15.8905 4.34609 15.6398 4.51891 15.4574C4.71755 15.2477 5.14678 15.1849 6.00524 15.0595L14.1573 13.8679C14.4109 13.8309 14.5376 13.8123 14.648 13.761C14.7458 13.7155 14.8338 13.6516 14.9071 13.5726C14.99 13.4834 15.0467 13.3685 15.1601 13.1388L18.8046 5.75549Z'
      stroke={stroke}
      strokeWidth='4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default Star01
