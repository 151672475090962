import { concatString } from './string'
import { v4 as uuidv4 } from 'uuid'

// Format an object to column format to be displayed in the Ant Design table
// Input: {name:'Alex', age: 24}
// OutPut: [{name: 'name', value: 'Alex'}, {name:'age', value:24}]
export const convertToKeyValue = (
  object: Record<string, unknown> | undefined,
): { name: string; value: unknown }[] => {
  const result: { name: string; value: unknown }[] = []

  const renderValue = (value: unknown) => {
    if (value == null) return 'N/A'
    if (value === '') return `''`
    return value
  }

  if (!object) return []
  for (const [key, value] of Object.entries(object)) {
    result.push({ name: key, value: renderValue(value) })
  }

  return result
}

// If object equals {}, null, undefined -> return true
export const isEmptyObject = (obj: unknown): boolean => {
  if (obj == null) return true
  if (typeof obj !== 'object') return false
  return Object.keys(obj as Record<string, unknown>).length === 0
}

export const flattenObject = (
  object: Record<string, unknown>,
  base = {},
  prevKey = '',
  separator = '/',
): Record<string, unknown> => {
  if (!object) return {}
  for (const [key, value] of Object.entries(object)) {
    const nextKey = concatString([prevKey, key], separator)
    if (value && typeof value === 'object') {
      flattenObject(value as Record<string, unknown>, base, nextKey, separator)
    } else {
      base[nextKey] = value
    }
  }
  return base
}

// Find highest value in all leafs across the object
export const findMaxValue = (dataSource: Record<string, unknown>): number => {
  let result = -Infinity

  if (!dataSource) return result

  Object.entries(dataSource).forEach(([_, value]) => {
    const maxValue =
      typeof value === 'object'
        ? findMaxValue(value as Record<string, unknown>)
        : (value as number)
    if (maxValue > result) result = maxValue
  })

  return result
}

// Check if the child has any number value
export const isChildValueNumber = (object: Record<string, unknown>): boolean =>
  Object.values(object).some(value => typeof value === 'number')

// Calculate the sum of all children values
export const getSumOfChildrenValues = (object: Record<string, unknown>): number => {
  return Object.values(object).reduce((acc: number, cur) => {
    if (typeof cur === 'number') {
      return acc + cur
    }
    return acc
  }, 0)
}

// Find the node with highest sum of leafs
export const findMaxLeafSum = (dataSource: Record<string, unknown>): number => {
  let result = 0

  if (!dataSource) return result

  Object.entries(dataSource).forEach(([_, value]) => {
    let maxLeafSum = 0

    if (value == null || !['number', 'object'].includes(typeof value)) return

    if (!isChildValueNumber(value as Record<string, unknown>)) {
      maxLeafSum = findMaxLeafSum(value as Record<string, unknown>)
    } else {
      maxLeafSum = getSumOfChildrenValues(value as Record<string, unknown>)
    }

    if (maxLeafSum > result) result = maxLeafSum
  })

  return result
}

// Create nested object
// @param base - the original object
// @param keys - a list of nested keys
// @param value - the value for the last level
// @return an nested object
// -> createNestedObject({value: 5}, ['event', 'AddItems', 'Hanoi'], 5)
// -> {value: 5, event:{AddItems:{Hanoi:5}}}
// Reference: https://stackoverflow.com/questions/5484673/javascript-how-to-dynamically-create-nested-objects-using-object-names-given-by
export const createNestedObject = function (
  base: Record<string, any>,
  keys: string[],
  value: any,
): Record<string, unknown> {
  // If a value is given, remove the last key and keep it for later:
  const lastKey = arguments.length === 3 ? keys.pop() : false

  // Walk the hierarchy, creating new objects where needed.
  // If the lastKey was removed, then the last object is not set yet
  for (const key of keys) {
    base = base[key] = base[key] || {}
  }

  // If a value was given, set it to the last name:
  if (lastKey) {
    base = base[lastKey] = value
  }

  // Return the last object in the hierarchy:
  return base
}

// Input
// @params nestedArray: ['filter1', ['filter2', 'filter3']]
// @params indices: [1, 0]
// Output: 'filter2'
type ValueOrArray<T> = T | ValueOrArray<T>[]
type NestedArray = ValueOrArray<unknown>
export const getNestedArrayValue = (
  nestedArray: NestedArray,
  indices: number[],
): string | number | boolean | NestedArray | undefined => {
  if (!indices?.length) return nestedArray

  if (!Array.isArray(nestedArray)) return undefined
  // Only access array value by index if nestedArray is an array.
  // Otherwise, we will encounter nestedArray as a string (eg: 'filter')
  // We don't want this function to return invalid value("f") when the path is not existed
  if (indices.length > 1) {
    return getNestedArrayValue(nestedArray[indices[0]], indices.slice(1))
  }

  return nestedArray[indices[0]]
}

// Input
// nestedArray: ['filter1', ['filter2', 'filter3']]
// indices: [1]
// value: {name: 'filter4'}
// Output:  ['filter1', ['filter2', 'filter3', {name: 'filter4'}]]
export const appendNestedArrayValue = (
  nestedArray: any[],
  indices: number[],
  value: unknown,
): void => {
  if (!indices?.length) {
    nestedArray.push(value)
    return
  }

  if (indices.length > 1) {
    appendNestedArrayValue(nestedArray[indices[0]], indices.slice(1), value)
    return
  }

  if (!nestedArray[indices[0]]) {
    nestedArray[indices[0]] = []
  }

  nestedArray[indices[0]].push(value)
}

export const updateNestedArrayValue = (
  nestedArray: any[],
  indices: number[],
  updatedValue: unknown,
): void => {
  if (!indices?.length) return
  if (indices.length > 1) {
    updateNestedArrayValue(nestedArray[indices[0]], indices.slice(1), updatedValue)
    return
  }
  nestedArray[indices[0]] = updatedValue
}

export const deleteNestedArrayValue = (nestedArray: any[], indices: number[]): void => {
  if (!indices?.length) return
  if (indices.length > 1) {
    deleteNestedArrayValue(nestedArray[indices[0]], indices.slice(1))
    return
  }

  nestedArray.splice(indices[0], 1)
}

// Check if two array have the same element without considering the order
// It also work with an array of objects if the order of object properties are not changed
// WORK!
// Input: [1,2,3]
// Output: [1,3,2]
// Result: true
//
// NOT WORK!
// Input: [1, {name:'Alex', age: 24}]
// Input: [1, {age: 24, name:'Alex'}]
// Result: false
export const equalArrayWithoutOrder = (
  arr1: unknown[] | undefined,
  arr2: unknown[] | undefined,
): boolean => {
  if (arr1 === undefined || arr2 === undefined || arr1.length !== arr2.length) {
    return false
  }

  const stringifiedArray1 = arr1.map(item => JSON.stringify(item)).sort()
  const stringifiedArray2 = arr2.map(item => JSON.stringify(item)).sort()

  return stringifiedArray1.every((item, index) => item === stringifiedArray2[index])
}

// Remove null value in nest object with out mutate the original object
export const removeNullInObject = (
  object: Record<string, unknown> | undefined = {},
): Record<string, unknown> => {
  const newObj = {}
  Object.entries(object).forEach(([key, value]: [string, unknown]) => {
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      const childObject = removeNullInObject(value as Record<string, unknown>)
      if (!isEmptyObject(childObject)) {
        newObj[key] = childObject
      }
    } else {
      if (value !== null) {
        newObj[key] = value
      }
    }
  })
  return newObj
}

export const nestedSum = (data: Record<string, any>): number => {
  let sum = 0
  Object.entries(data).forEach(([_, value]) => {
    if (typeof value === 'object') {
      sum += nestedSum(value)
      return
    }
    sum += value as number
  })
  return sum
}

// TODO: write test
export const excludeObjectKeys = (
  object: object | Record<string, unknown>,
  excludedKeys: string[] = [],
): Record<string, unknown> => {
  return Object.keys(object).reduce((acc, currentKey) => {
    if (excludedKeys.includes(currentKey)) return acc
    acc[currentKey] = object[currentKey]
    return acc
  }, {})
}

// Generate id property in array recursively
// Usecase: generate id for filters in report
export const generateIdRecursively = (data: any[] = []): any[] => {
  return data.map(item => {
    if (Array.isArray(item)) {
      return generateIdRecursively(item)
    }

    if (typeof item === 'object' && item != null) {
      return { ...item, id: item.id ? item.id : uuidv4() }
    }

    return item
  })
}

// Chain the key to create a series name in nested chart data reponse
// Eg:
// source: {'A. cart_viewed': {'2020-12-01T00:00:00': { track: 2223 }}}
// target: {}
// result: {' / A. cart_viewed / 2020-12-01T00:00:00 / track': 2223 }
export const combineKey = (
  target: Record<string, Record<string, unknown>>,
  source: Record<string, unknown>,
  prevKey = '',
): void => {
  if (typeof source !== 'number') {
    Object.keys(source).forEach(key => {
      const newKey = concatString([prevKey, key], '/')
      combineKey(target, source[key] as Record<string, unknown>, newKey)
    })
    return
  }
  target[prevKey] = source
}
