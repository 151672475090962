export enum Status {
  Active = 'active',
  Completed = 'completed',
  InActive = 'inactive',
  Syncing = 'syncing',
  Pending = 'pending',
  Running = 'running',
  Calculating = 'calculating',
  Deleted = 'deleted',
  Failed = 'failed',
  Error = 'error',
  Open = 'open',
  Closed = 'closed',
  Solved = 'solved',
  Draft = 'draft',
  Inactive = 'inactive',
  Done = 'done',
  Scheduled = 'scheduled',
  Approved = 'approved',
  Rejected = 'rejected',
  Resolved = 'resolved',
  CusCheck = 'cus_check',
}
