import { message } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const Copyable: React.FC<{ title: string; value: string; isLink?: boolean }> = ({
  title,
  value,
  isLink = false,
}) => {
  const { t } = useTranslation()
  return (
    <div className='w-full px-4 py-1.5 rounded-none border border-solid border-wedgewood-200 justify-start items-center gap-4 inline-flex'>
      <div className='grow shrink basis-0 h-[42px] justify-start items-center gap-2 flex'>
        <div className='grow shrink basis-0 pb-0.5 flex-col justify-start items-start gap-0.5 inline-flex'>
          <div className='self-stretch text-wedgewood-500 text-xs font-normal leading-[18px]'>
            {title}
          </div>
          {isLink ? (
            <a
              href={value}
              target='_blank'
              rel='noreferrer'
              className='self-stretch cursor-pointer'>
              <input
                type='text'
                className='w-full text-black-pearl-600 text-sm font-normal bg-transparent border-none outline-none cursor-pointer'
                value={value}
                readOnly
              />
            </a>
          ) : (
            <input
              type='text'
              className='w-full text-sm font-normal bg-transparent border-none outline-none'
              value={value}
              readOnly
            />
          )}
        </div>
      </div>
      <div className='justify-center items-center gap-2 flex'>
        <a
          onClick={() => {
            navigator.clipboard.writeText(value)
            message.success(t('Đã sao chép!'))
          }}
          type='link'
          className='text-black-pearl-600 text-sm font-semibold leading-tight'>
          {t('Sao chép')}
        </a>
      </div>
    </div>
  )
}
