import React, { SVGProps } from 'react'

const User01: React.FC<SVGProps<SVGSVGElement>> = ({
  width = 20,
  height = 20,
  stroke = '#667085',
  fill = 'none',
  ...props
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    fill={fill}
    viewBox='0 0 20 20'
    {...props}>
    <path
      stroke={stroke}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.67}
      d='M16.6666 17.5C16.6666 16.337 16.6666 15.7555 16.5231 15.2824C16.1999 14.217 15.3663 13.3834 14.3009 13.0602C13.8278 12.9167 13.2463 12.9167 12.0833 12.9167H7.91665C6.75368 12.9167 6.17219 12.9167 5.69903 13.0602C4.63369 13.3834 3.80001 14.217 3.47685 15.2824C3.33331 15.7555 3.33331 16.337 3.33331 17.5M13.75 6.25C13.75 8.32107 12.071 10 9.99998 10C7.92891 10 6.24998 8.32107 6.24998 6.25C6.24998 4.17893 7.92891 2.5 9.99998 2.5C12.071 2.5 13.75 4.17893 13.75 6.25Z'
    />
  </svg>
)

export default User01
