import { Form, FormItemProps } from 'antd'
import { NamePath } from 'antd/es/form/interface'
import classNames from 'classnames'
import React, { useState } from 'react'

type Props = FormItemProps & {
  className?: string
  listName?: string | any[]
  hasValue?: boolean
  uncontrolled?: boolean
}

export const FormItem: React.FC<Props> = props => {
  const [focus, setFocus] = useState(false)
  const form = Form.useFormInstance()

  const {
    required,
    children,
    className,
    label,
    hasValue: hasValueProp,
    listName,
    uncontrolled = false,
    ...rest
  } = props
  const name = !listName
    ? props.name || ''
    : [
        ...(Array.isArray(listName) ? listName : [listName]),
        ...(Array.isArray(props.name) ? props.name : [props.name]),
      ]
  const value = Form.useWatch(name as NamePath, form)
  const hasValue = Array.isArray(value) ? value.length > 0 : value != null

  const labelAsPlaceholder = !(focus || hasValue || hasValueProp)
  const labelColor = focus
    ? 'text-[var(--primary-color,#2970ff)]'
    : 'text-[var(--primary-color,#667085)]'

  return (
    <div
      className={classNames(className, 'relative')}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}>
      <Form.Item
        className='m-0'
        {...rest}
        name={uncontrolled ? undefined : rest.name}
        style={
          {
            '--form-item-height': !label ? '32px' : '52px',
            '--input-padding-top': !label ? '16px' : '24px',
          } as any
        }>
        {children}
      </Form.Item>
      {uncontrolled && (
        <Form.Item
          name={rest.name}
          rules={rest.rules}
          className='[&_.ant-form-item-control-input]:hidden mb-0 [&.ant-form-item-has-error]:mb-8'
        />
      )}
      <label
        className={classNames(
          'z-[1] text-base md:text-sm font-medium absolute top-4 left-4 transition-all ease-in pointer-events-none',
          labelColor,
          { '!top-1.5 !text-xs': !labelAsPlaceholder, 'post-asterisk': required },
        )}>
        {label}
      </label>
    </div>
  )
}
