import { Button, Col, Row, Space, Typography } from 'antd'
import React from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export const ErrorBoundary: React.FC<{ children?: React.ReactNode }> = ({
  children,
  ...props
}) => {
  const { t } = useTranslation()
  return (
    <ReactErrorBoundary
      fallbackRender={({ resetErrorBoundary }) => (
        <Space direction='vertical' style={{ textAlign: 'center', marginTop: '2rem' }}>
          <Typography.Title level={4}>
            {t('Sorry, the application has encountered an unexpected error')}
          </Typography.Title>
          <Col span={12} style={{ margin: '0 auto' }}>
            <Typography.Text type='secondary'>
              {t(
                'Our technical staff have been automatically notified and will be looking into this with the utmost urgency. In the meantime, you might want to retry or use other unrelated features',
              )}
            </Typography.Text>
          </Col>

          <Row
            style={{ width: '100%', marginTop: '1.5rem' }}
            justify='center'
            align='middle'>
            <Typography.Link
              onClick={() => resetErrorBoundary()}
              style={{ marginRight: '1.5rem' }}>
              <Link to='/'>{t('Back to home page')}</Link>
            </Typography.Link>

            <Button type='primary' onClick={() => resetErrorBoundary()}>
              {t('Retry')}
            </Button>
          </Row>
        </Space>
      )}
      {...props}>
      {children}
    </ReactErrorBoundary>
  )
}
