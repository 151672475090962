import {
  FieldNumberOutlined,
  FieldStringOutlined,
  FieldTimeOutlined,
} from '@ant-design/icons'

export enum DataType {
  Integer = 'integer',
  String = 'string',
  Timestamp = 'timestamp',
  Float = 'float',
  List = 'list',
  Number = 'number',
  Date = 'date',
  Datetime = 'datetime',
}

export const numberDataTypes = [DataType.Integer, DataType.Float]

export const dataTypeOptions = [
  { name: 'String', value: DataType.String },
  {
    name: 'Float',
    value: DataType.Float,
  },
  { name: 'Datetime', value: DataType.Timestamp },
  { name: 'Integer', value: DataType.Integer },
]

export enum OperatorType {
  Equal = 'equal',
  NotEqual = 'not_equal',
  Contain = 'contain',
  NotContain = 'not_contain',
  Any = 'any',
  All = 'all',
  LessThan = 'less_than',
  LessThanOrEqualTo = 'less_than_or_equal_to',
  GreaterThan = 'greater_than',
  GreaterThanOrEqualTo = 'greater_than_or_equal_to',
  Between = 'between',
  IsNull = 'null',
  IsNotNull = 'not_null',
  InTheNext = 'in_the_next',
  InTheLast = 'in_the_last',
  BetweenSelectedTimeWindow = 'between_selected_time_window',
}

export const noValueOperators = [
  OperatorType.IsNull,
  OperatorType.IsNotNull,
  OperatorType.BetweenSelectedTimeWindow,
]

export const checkboxOperators = [OperatorType.Equal, OperatorType.NotEqual]

export enum CustomPropertyBuilderType {
  EventCounter = 'event_counter',
  Aggregation = 'aggregation',
  TimeDelta = 'time_delta',
}

export enum AggregationType {
  Sum = 'sum',
  Max = 'max',
  Min = 'min',
  Average = 'avg',
  Count = 'count',
  CountDistinct = 'count_distinct',
  Unique = 'unique',
  FirstValue = 'first_value',
  LastValue = 'last_value',
  Dau = 'dau',
  Wau = 'wau',
  Mau = 'mau',
}

export const eventAggregationTypes = [
  {
    name: 'Sum',
    value: AggregationType.Sum,
  },
  {
    name: 'Max',
    value: AggregationType.Max,
  },
  {
    name: 'Min',
    value: AggregationType.Min,
  },
  {
    name: 'Average',
    value: AggregationType.Average,
  },
  {
    name: 'Count',
    value: AggregationType.Count,
  },
  {
    name: 'Count Distinct',
    value: AggregationType.CountDistinct,
  },
  {
    name: 'Unique',
    value: AggregationType.Unique,
  },
  {
    name: 'DAU',
    value: AggregationType.Dau,
  },
  {
    name: 'WAU',
    value: AggregationType.Wau,
  },
  {
    name: 'MAU',
    value: AggregationType.Mau,
  },
]

export const customPropertyAggregationTypes = [
  { name: 'First Value', value: AggregationType.FirstValue },
  { name: 'Last Value', value: AggregationType.LastValue },
]

const numberCountMethods = [
  AggregationType.Sum,
  AggregationType.Min,
  AggregationType.Max,
  AggregationType.Average,
  AggregationType.Count,
  AggregationType.CountDistinct,
  AggregationType.Unique,
  AggregationType.FirstValue,
  AggregationType.LastValue,
]

export const countMethodsByDataType = {
  [DataType.Float]: numberCountMethods,
  [DataType.Integer]: numberCountMethods,
  [DataType.Timestamp]: [
    AggregationType.Min,
    AggregationType.Max,
    AggregationType.CountDistinct,
    AggregationType.FirstValue,
    AggregationType.LastValue,
  ],
  [DataType.String]: [
    AggregationType.CountDistinct,
    AggregationType.FirstValue,
    AggregationType.LastValue,
  ],
}

export const Operator = {
  Equal: { name: 'equals', value: OperatorType.Equal },
  NotEqual: { name: 'does not equal', value: OperatorType.NotEqual },
  Contain: { name: 'contains', value: OperatorType.Contain },
  NotContain: { name: 'does not contain', value: OperatorType.NotContain },
  Any: { name: 'any of the following values', value: OperatorType.Any },
  All: { name: 'all of the following values', value: OperatorType.All },
  LessThan: { name: 'less than', value: OperatorType.LessThan },
  GreaterThan: { name: 'greater than', value: OperatorType.GreaterThan },
  GreaterThanOrEqualTo: {
    name: 'greater than or equal to',
    value: OperatorType.GreaterThanOrEqualTo,
  },
  LessThanOrEqualTo: {
    name: 'less than or equal to',
    value: OperatorType.LessThanOrEqualTo,
  },
  Between: { name: 'between', value: OperatorType.Between },
  IsNull: { name: 'is null', value: OperatorType.IsNull },
  IsNotNull: { name: 'is not null', value: OperatorType.IsNotNull },
  InTheLast: { name: 'in the last', value: OperatorType.InTheLast },
  InTheNext: { name: 'in the next', value: OperatorType.InTheNext },
  BetweenSelectedTimeWindow: {
    name: 'between selected time window',
    value: OperatorType.BetweenSelectedTimeWindow,
  },
}
export const OperatorDisplayNameMappings = Object.values(Operator).reduce(
  (acc, operator) => {
    acc[operator.value] = operator.name
    return acc
  },
  {},
)
export const EquivalentOperators = [Operator.Equal, Operator.NotEqual]
const NullOperators = [Operator.IsNull, Operator.IsNotNull]

const NumberOperators = [
  ...EquivalentOperators,
  Operator.LessThan,
  Operator.LessThanOrEqualTo,
  Operator.GreaterThan,
  Operator.GreaterThanOrEqualTo,
  Operator.Between,
  ...NullOperators,
]

export const SingleAnswersOperators = [
  ...EquivalentOperators,
  Operator.Any,
  ...NullOperators,
]

export const PropertyOperation = {
  [DataType.String]: [
    ...EquivalentOperators,
    Operator.Contain,
    Operator.NotContain,
    ...NullOperators,
  ],
  [DataType.List]: [Operator.All, Operator.Any],
  [DataType.Integer]: NumberOperators,
  [DataType.Float]: NumberOperators,
  [DataType.Timestamp]: [...NumberOperators, Operator.InTheLast, Operator.InTheNext],
}

export const DataTypePrefix = {
  [DataType.String]: FieldStringOutlined,
  [DataType.Integer]: FieldNumberOutlined,
  [DataType.Timestamp]: FieldTimeOutlined,
  [DataType.Float]: FieldNumberOutlined,
}
export enum AlignPoints {
  BottomLeft = 'bl',
  BottomRight = 'br',
  TopLeft = 'tl',
  TopRight = 'tr',
}

export enum SortOrder {
  Ascend = 'asc',
  Descend = 'desc',
}

export enum TableSortOrder {
  Ascend = 'ascend',
  Descend = 'descend',
}

export const SortOrderMapping = {
  ascend: SortOrder.Ascend,
  descend: SortOrder.Descend,
}

export enum UserPropertyType {
  Default = 'default',
  Custom = 'custom',
}

export enum Status {
  Active = 'active',
  Completed = 'completed',
  InActive = 'inactive',
  Syncing = 'syncing',
  Pending = 'pending',
  Running = 'running',
  Calculating = 'calculating',
  Deleted = 'deleted',
  Failed = 'failed',
  Error = 'error',
  Open = 'open',
  Closed = 'closed',
  Solved = 'solved',
  Draft = 'draft',
  Inactive = 'inactive',
  Done = 'done',
  Scheduled = 'scheduled',
  Approved = 'approved',
  Rejected = 'rejected',
  Resolved = 'resolved',
}

export const ETLStatusDisplay = {
  [Status.Pending]: {
    status: 'default',
    label: 'Pending',
  },
  [Status.Failed]: {
    status: 'error',
    label: 'Failed',
  },
}

export const ETLExceptions = new Set([Status.Pending, Status.Failed])

export enum DebuggerDataType {
  String = 'string_value',
  Integer = 'int_value',
  Double = 'double_value',
  Datetime = 'datetime_value',
}

export enum CustomPropertyDataType {
  String = 'string_value',
  Integer = 'integer_value',
  Double = 'double_value',
  Datetime = 'datetime_value',
}

export const debuggerDataTypes = [
  DebuggerDataType.String,
  DebuggerDataType.Integer,
  DebuggerDataType.Double,
  DebuggerDataType.Datetime,
]

export enum ComponentType {
  Page = 'page',
  EventStreamDetail = 'event_stream_detail',
  ActionTab = 'action_tab',
}

export enum EventType {
  Track = 'track',
  Identify = 'identify',
}

export const eventTypes = [
  { name: 'Track', value: EventType.Track },
  { name: 'Identify', value: EventType.Identify },
]

export const requiredPropertyAggregations = [
  AggregationType.Min,
  AggregationType.Max,
  AggregationType.Average,
  AggregationType.Sum,
  AggregationType.CountDistinct,
  AggregationType.FirstValue,
  AggregationType.LastValue,
]

export const aggregatableByString = [AggregationType.CountDistinct]

export const aggregatableByTimestamp = [
  AggregationType.Min,
  AggregationType.Max,
  AggregationType.Average,
  AggregationType.CountDistinct,
]

export enum DefaultSegment {
  AllUsers = '$all_users',
  IdentifiedUsers = '$identified_users',
}

export const FilumUserProperty = {
  Name: 'name',
  Phone: 'phone',
  Email: 'email',
  Gender: 'gender',
  Address: 'address',
  Username: 'username',
  Avatar: 'avatar',
}

export enum EventName {
  AllEvents = 'All events',
  FeedbackReceived = 'Feedback Received',
  SurveyAnswered = 'Survey Answered',
  SurveySent = 'Survey Sent',
  SurveyOpened = 'Survey Opened',
  SurveySendingFailed = 'Survey Sending Failed',
  PartialSurveyAnswered = 'Partial Survey Answered',
  TicketResolved = 'Ticket Resolved',
  TicketClosed = 'Ticket Closed',
  ReviewReceived = 'Review Received',
  QuestionAnswered = 'Question Answered',
}

export enum EventSlug {
  Identify = 'identify',
  AllEvents = 'events',
  SurveyAnswered = 'survey_answered',
  SurveySendingFailed = 'survey_sending_failed',
  TicketOpened = 'ticket_opened',
  TicketResolved = 'ticket_resolved',
  TicketClosed = 'ticket_closed',
  ProductViewed = 'product_viewed',
  TransactionCompleted = 'transaction_completed',
  FeedbackReceived = 'feedback_received',
}

export enum EventPropertySlug {
  EventName = 'event_name',
  EventType = 'event_type',
  EventId = 'event_id',
  AnonymousId = 'anonymous_id',
  UserId = 'user_id',
  SentAt = 'sent_at',
  ReceivedAt = 'received_at',
  OriginalTimestamp = 'original_timestamp',
  Origin = 'origin',
  CampaignId = 'campaign_id',
  OnboardingInstalledSourceId = 'onboarding_installed_source_id',
  AutomatedActionId = 'automated_action_id',
  VendorId = 'vendor_id',
  ResponseType = 'responses_type',
}

export const BaseEventPropertySlugs = new Set([
  EventPropertySlug.EventName,
  EventPropertySlug.EventType,
  EventPropertySlug.EventId,
  EventPropertySlug.AnonymousId,
  EventPropertySlug.UserId,
  EventPropertySlug.SentAt,
  EventPropertySlug.ReceivedAt,
  EventPropertySlug.OriginalTimestamp,
  EventPropertySlug.Origin,
])

export enum FunctionName {
  ExchangeFacebookToken = 'exchange_facebook_token',
  ExchangeGoogleToken = 'exchange_google_token',
  GetGoogleAdsAccounts = 'get_google_ads_accounts',
  GetGoogleBusinessLocations = 'get_google_business_locations',
}

export enum SubscriberType {
  Action = 'action',
}

export enum SubscriptionObjectType {
  SEGMENT = 'segment',
}

export enum InputType {
  Text = 'text',
  Email = 'email',
  TextArea = 'textarea',
  Select = 'select',
  FacebookAuth = 'facebook_auth',
  GoogleAuth = 'google_auth',
  Number = 'number',
  Password = 'password',
  SegmentSelect = 'segment_select',
  Oauth = 'oauth',
  Tag = 'tag',
  MemberSelect = 'member_select',
  ZnsTemplateSelect = 'zns_template_select',
}

export enum SolutionGroup {
  EX = 'EX',
  CX = 'CX',
  VOC = 'VOC',
}

export enum Solution {
  CDP = 'cdp',
  DynamicFeedback = 'dynamic_feedback',
  Customer360 = 'customer360',
  ConversationAnalysis = 'conversation_analysis',
  TextAnalysis = 'text_analysis',
}

export enum SourceType {
  EVENT = 'event',
  SEGMENT = 'segment',
}

export enum SegmentCampaignRunType {
  ONCE = 'once',
  CONTINUOUS = 'continuous',
  SCHEDULED = 'scheduled',
  ON_JOIN = 'on_join',
}

export enum UserType {
  ADMIN = 'admin',
  SUPER_ADMIN = 'super_admin',
  MEMBER = 'member',
  CUSTOMER = 'customer',
}

export enum Environment {
  PRODUCTION = 'production',
  STAGING = 'staging',
  LOCAL = 'local',
}

export const demoEnvironments = [Environment.STAGING]
