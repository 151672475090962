import { SortOrder, Status } from '../main'
import { LogicOperator, Operator } from './common'

export enum ViewMode {
  Desktop = 'desktop',
  Mobile = 'mobile',
  Tablet = 'tablet',
  Email = 'email',
  Embed = 'embed',
}

export enum QuestionType {
  MultipleChoice = 'multiple_choice',
  Checkbox = 'checkbox',
  Text = 'text',
  Matrix = 'matrix',
  NPS = 'nps',
  Csat = 'csat',
  Ces = 'ces',
}

export enum SurveyType {
  Csat = 'csat',
  Form = 'form',
  Nps = 'nps',
  Ces = 'ces',
}

export enum ReviewUrlType {
  SingleUrl = 'single_url',
  MappingUrl = 'metadata',
}

export type ExtendedQuestionType = QuestionType | SurveyType

export enum MetricType {
  Csat = 'csat',
  Nps = 'nps',
  Ces = 'ces',
  Rating = 'rating',
}

export const ActiveMetricTypes = [MetricType.Csat, MetricType.Nps, MetricType.Ces]

export enum ConditionPropertyType {
  Question = 'question',
  Param = 'param',
}

interface LogicCondition {
  property: {
    type: ConditionPropertyType
    id: string | number
    sub_id: string | number
  }
  operator: Operator
  values: Array<string>
}

export type DisplayLogic = {
  operator: LogicOperator
  conditions: Array<LogicCondition>
}

export type MatrixRowType = {
  id: number
  text: string
}

export type QuestionConfig = {
  is_overall?: boolean
  is_expectation?: boolean
  metric_type?: string
  object_type?: string
  object_id?: number
  object_name?: string
  sub_object_type?: string
  follow_up_for_question_id?: number
}

export type Question = {
  id: number
  title: string
  description?: string
  type: QuestionType
  required: boolean
  options?: any[]
  rows?: MatrixRowType[]
  columns?: string[] | MatrixRowType[]
  other_text?: string
  has_page_break?: boolean
  display_logic?: DisplayLogic
  style?: QuestionStyle
  metric_type?: MetricType
  weight_order?: SortOrder
  max_selections?: number
  is_fixed?: boolean
  config?: QuestionConfig
}

export interface TouchpointConfig {
  id: number
  name: string
  statistics?: {
    metric_type: string
    score: number
  }[]
}

export interface SurveyCampaign {
  id: string
  touchpoints: TouchpointConfig[]
  metadataMappings?: Record<string, string>
  answers?: Record<number, any>
  survey: {
    id: 0
    description?: string
    branding_colors?: string[]
    logo?: {
      light_mode_url: string
    }
    layout?: SurveyLayout
    pattern?: SurveyPattern
    background_image_url?: string
    name?: string
    type?: SurveyType
    status?: string
    questions: Question[]
    inputs?: Record<string, any>[]
    input_data?: Record<string, any>
    welcome_page?: Record<SurveyScreenFormField, any>
    information_page?: Record<SurveyScreenFormField, any>
    completion_page?: Record<SurveyScreenFormField, any> & {
      review_button: {
        enabled: boolean
        label: string
        use_single_url: boolean
        metadata_variable: string
        url: string
        url_mappings: Record<string, string>
        display_logic: DisplayLogic
      }
    }
    expiration_page?: Record<SurveyScreenFormField, any>
    footer_url?: string
    languages?: Record<string, string>[]
    translations?: Record<string, any>
    font_urls?: Record<string, string>
    auto_submit?: boolean
    icon_label_enabled?: boolean
    multi_question_page_enabled?: boolean
  }
  survey_campaign?: {
    id: string
    name: string
    feedback_type: MetricType
    description?: string
    status: Status
    partial_record?: boolean
  }
  public_link?: string
  expiration_time?: number
  is_answered?: boolean
  identifier?: string
  status: Status
}

export enum QuestionStyle {
  Face = 'face',
  Star = 'star',
  Score = 'score',
  ColoredScore = 'colored_score',
  Thumb = 'thumb',
  Slider = 'slider',
}

interface LogicCondition {
  property: {
    type: ConditionPropertyType
    id: string | number
    sub_id: string | number
  }
  operator: Operator
  values: Array<string>
}

export enum SurveyFormField {
  Title = 'title',
  Description = 'description',
  Logo = 'logo',
  LightLogoUrl = 'light_mode_url',
  BrandingColors = 'branding_colors',
  Questions = 'questions',
  WelcomeScreen = 'welcome_page',
  InfoScreen = 'information_page',
  CompletionScreen = 'completion_page',
  ExpirationScreen = 'expiration_page',
  ThemeId = 'theme_id',
  Layout = 'layout',
  Pattern = 'pattern',
  Languages = 'languages',
  DefaultLanguage = 'default_language',
  Translations = 'translations',
  SystemConfig = 'system_config',
  AutoSubmit = 'auto_submit',
  IconLabelEnabled = 'icon_label_enabled',
  MultiQuestionPageEnabled = 'multi_question_page_enabled',
}

export enum SurveyLogoFormField {
  LightModeUrl = 'light_mode_url',
  DarkModeUrl = 'dark_mode_url',
  UseDefault = 'use_default',
  IsDefault = 'is_default',
}

export enum SurveyScreenFormField {
  Enabled = 'enabled',
  Message = 'message',
  Button = 'button_label',
  Title = 'title',
  Inputs = 'inputs',
  QuestionLabel = 'question_label',
  AnswerField = 'answer_field',
  NextButton = 'next_button_label',
  SendButton = 'send_button_label',
  SkipButton = 'skip_button_label',
  ReviewButton = 'review_button',
  RestartSurveyButton = 'restart_survey_button_label',
  CsatVeryDissatisfied = 'csat_very_dissatisfied',
  CsatVerySatisfied = 'csat_very_satisfied',
}

export enum ReviewButtonFormField {
  UrlType = 'url_type',
  Enabled = 'enabled',
  Url = 'url',
  UseSingleUrl = 'use_single_url',
  DisplayLogic = 'display_logic',
  MetaDataVariable = 'metadata_variable',
  Label = 'label',
  UrlMappings = 'url_mappings',
}

export enum SurveyScreenInputFormField {
  Id = 'id',
  Name = 'name',
  Required = 'is_required',
  Type = 'type',
}

export enum SurveyLayout {
  DEFAULT = 'default',
  PROMINENT = 'prominent',
  SUBTLE = 'subtle',
}

export enum SurveyPattern {
  NUMBERS = 'numbers',
  CIRCLES = 'circles',
  VEHICLES = 'vehicles',
  CHINESE = 'chinese',
}
