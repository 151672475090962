export enum Operator {
  Equal = 'equal',
  NotEqual = 'not_equal',
  Contain = 'contain',
  NotContain = 'not_contain',
  LessThan = 'less_than',
  LessThanOrEqualTo = 'less_than_or_equal_to',
  GreaterThan = 'greater_than',
  GreaterThanOrEqualTo = 'greater_than_or_equal_to',
  Between = 'between',
  IsNull = 'null',
  IsNotNull = 'not_null',
  Any = 'any',
  All = 'all',
}

export enum LogicOperator {
  AND = 'AND',
  OR = 'OR',
}

export interface BaseCondition {
  operator: Operator
  values: string[]
}

export interface SelectOption {
  label: string
  value: number
}
