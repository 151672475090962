import { message } from 'antd'
import { AxiosError } from 'axios'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import IAMService from '@/services/IAM'
import { QueryKeys } from '@/services/types'
import { Workspace } from '@/types/server/workspace'
import { Solution } from '@/utilities/enum'

import { AuthContext } from './AuthProvider'
import { User, useUser } from './useUser'

type UseWorkspace = {
  user?: User
  workspace?: Workspace
  isLoading: boolean
  defaultSolution?: Solution
  set: (workspace?: Workspace) => void
  invalidate: () => void
}

export let globalWorkspace: Workspace | undefined

export const useWorkspace = (): UseWorkspace => {
  const { t } = useTranslation()
  const { user } = useUser()
  const authData = useContext(AuthContext)
  const queryClient = useQueryClient()

  const queryKeys = [QueryKeys.GetWorkspaces, user]

  const latestWorkspaceMutation = useMutation<void, AxiosError, string>(
    async workspaceID => {
      await IAMService.updateAccount({
        options: { data: { data: { latest_organization_id: workspaceID } } },
      })
    },
  )

  const { data: workspace, isLoading } = useQuery<Workspace, AxiosError>(
    queryKeys,
    async () => {
      const res = await IAMService.getWorkspaces({
        options: { params: { page: 1, size: 100 } },
      })

      let workspace = res.data.items.find(
        (workspace: Workspace) =>
          workspace.id === (user as User).data.latest_organization_id,
      )

      if (!workspace) {
        workspace = res.data.items.find(
          (workspace: Workspace) => workspace.platform_version === 2,
        )
      }
      const workspaceId = workspace?.id || res.data.items[0]?.id
      if (!workspaceId) {
        return
      }
      const workspaceRes = await IAMService.getWorkspace({
        variables: { workspaceId },
      })

      return workspaceRes.data
    },
    {
      staleTime: Infinity,
      enabled: !!user,
      onSuccess: nextWorkspace => {
        if (!nextWorkspace || nextWorkspace.is_showcase) {
          return
        }

        if (nextWorkspace?.id !== globalWorkspace?.id) {
          latestWorkspaceMutation.mutate(nextWorkspace.id)
        }

        globalWorkspace = nextWorkspace
      },
      keepPreviousData: true,
      // Not using handleAPIError here to prevent circular dependency
      onError: () => message.error(t('Error occurs when getting workspace info')),
    },
  )

  const set = async (nextWorkspace: Workspace | undefined) => {
    if (nextWorkspace && nextWorkspace.id) {
      const workspaceRes = await IAMService.getWorkspace({
        variables: { workspaceId: nextWorkspace.id },
      })
      queryClient.setQueryData(queryKeys, () => workspaceRes.data)
    }
  }

  const invalidate = () => {
    queryClient.invalidateQueries(queryKeys)
  }

  const defaultSolution = workspace?.is_freemium
    ? Solution.DynamicFeedback
    : Solution.Customer360

  return {
    user,
    workspace,
    isLoading,
    defaultSolution,
    set,
    invalidate,
  }
}
