import { Button, Dropdown, Popover, Skeleton, Typography } from 'antd'
import { MenuProps } from 'antd/lib'
import { AxiosError } from 'axios'
import { ChevronDown, ErrorBoundary, Menu01, Settings01, User01 } from 'packages/ui'
import React, { Suspense, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import { appRoutes } from '@/config/appRoutes'
import { AuthContext } from '@/contexts/AuthProvider'
import { deleteAllLocalItems } from '@/contexts/localItems'
import { useWorkspace } from '@/contexts/useWorkspace'
import useHandleAPIError from '@/hooks/useHandleAPIError'
import IAMService from '@/services/IAM'
import { QueryKeys } from '@/services/types'
import { FastPagination } from '@/types'
import { Workspace } from '@/types/server/workspace'

import Skeletons from '../Skeletons'
import Sidebar from './Sidebar'
import i18n, { Language } from '@/config/i18n'
import { getLocale } from '@/utilities/locale'

const PlatformLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { t } = useTranslation()
  const handleAPIError = useHandleAPIError()
  const [openMobileNavbar, setOpenMobileNavbar] = useState<boolean>(false)
  const authContext = useContext(AuthContext)

  const { workspace, set: setWorkspace, isLoading } = useWorkspace()

  const workspacesQuery = useQuery<FastPagination<Workspace>, AxiosError>(
    [
      QueryKeys.GetWorkspaces,
      {
        page: 1,
        size: 100,
      },
    ],
    async ({ queryKey }) => {
      const res = await IAMService.getWorkspaces({ options: { params: queryKey[1] } })
      return res.data
    },
    { onError: handleAPIError },
  )

  const handleChangeWorkspace = (e: any) => {
    const selectedWorkspace = workspacesQuery.data?.items.find(item => item.id === e.key)
    setWorkspace(selectedWorkspace)
    deleteAllLocalItems(false)
  }

  const logout = () => {
    authContext?.logout()
  }

  const workspaceItems: MenuProps['items'] =
    workspacesQuery.data?.items?.map(item => ({
      label: item.name,
      name: item.name,
      id: item.id,
      key: item.id,
      value: item.id,
      is_showcase: item.is_showcase,
      selectGroup: item.is_showcase ? t('Sample datasets') : t('Your workspaces'),
    })) || []

  const workspaceMenuProps = {
    items: workspaceItems,
    selectable: true,
    defaultSelectedKeys: workspace?.id ? [workspace.id] : undefined,
    onClick: handleChangeWorkspace,
  }

  const handleChangeLanguage = (e: any) => {
    i18n.changeLanguage(e.key)
  }

  const languageItemsByKey: Record<string, any> = {
    [Language.Vi]: {
      key: Language.Vi,
      label: t('Tiếng Việt'),
      icon: <img src='/vi-flag.png' alt='vi' />,
    },
    [Language.Cn]: {
      key: Language.Cn,
      label: t('Tiếng Trung'),
      icon: <img src='/ch-flag.png' alt='ch' />,
    },
    [Language.En]: {
      key: Language.En,
      label: t('Tiếng Anh'),
      icon: <img src='/en-flag.png' alt='en' />,
    },
  }

  const languageMenuProps = {
    items: Object.values(languageItemsByKey),
    selectable: true,
    onClick: handleChangeLanguage,
  }

  const currentLanguageItem = languageItemsByKey[getLocale(i18n.language)]

  return (
    <div className='flex w-screen h-screen bg-wedgewood-50'>
      <Sidebar openMobileNavbar={openMobileNavbar} />
      {openMobileNavbar && (
        <div
          className='fixed top-0 left-0 z-[98] w-screen h-screen backdrop-blur-sm'
          onClick={() => setOpenMobileNavbar(false)}
          style={{
            backgroundColor: 'rgba(17, 25, 39, 0.75)',
            opacity: 1,
            transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          }}
        />
      )}
      <div className='flex-1 flex flex-col overflow-x-hidden relative'>
        <div className='flex gap-4 px-6 py-3 w-full justify-end items-baseline'>
          <div
            className='cursor-pointer mr-auto xl:hidden p-[10px] bg-white rounded-full w-10 h-10'
            onClick={() => setOpenMobileNavbar(true)}
            style={{
              boxShadow:
                '0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)',
            }}>
            <Menu01 width={20} height={20} stroke='#667085' />
          </div>
          {!!workspace && (
            <>
              <Dropdown
                menu={workspaceMenuProps}
                trigger={['click']}
                className='w-fit md:w-[139px] relative md:-top-[6px]'>
                <Button className='p-2 md:px-[14px] border-none rounded bg-white'>
                  <Typography.Text
                    className='!hidden md:!inline-block font-semibold text-wedgewood-500'
                    ellipsis>
                    {workspace?.name}
                  </Typography.Text>
                  <ChevronDown className='shrink-0' />
                </Button>
              </Dropdown>
              <Link to={appRoutes.settings} className='text-wedgewood-500'>
                <Settings01 width={24} height={24} />
              </Link>
            </>
          )}
          {/* <Dropdown
            menu={languageMenuProps}
            trigger={['click']}
            className='w-fit md:w-[139px]'>
            <Button
              icon={currentLanguageItem.icon}
              className='p-2 md:px-[14px] rounded-[36px] bg-white'>
              {currentLanguageItem.label}
            </Button>
          </Dropdown> */}
          <Popover
            getPopupContainer={trigger => trigger.parentElement as HTMLElement}
            content={
              <Button
                className='border-none'
                onClick={() => {
                  logout()
                }}>
                {t('Logout')}
              </Button>
            }
            className='p-0'
            overlayInnerStyle={{ padding: 0 }}
            rootClassName='p-0'
            arrow={false}
            trigger='click'
            destroyTooltipOnHide={true}
            placement='bottomRight'>
            <User01 className='cursor-pointer' width={24} height={24} />
          </Popover>
        </div>
        {isLoading || !workspace ? (
          <Skeletons count={3} />
        ) : (
          <Suspense fallback={<Skeleton active className='p-6' />}>
            <ErrorBoundary>
              <div className='flex-1 layout-content overflow-auto'>{children}</div>
            </ErrorBoundary>
          </Suspense>
        )}
      </div>
    </div>
  )
}

export default PlatformLayout
